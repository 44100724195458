import React from "react";
import "../Assets/Styles/Placements.css";
import { IMAGES } from "../utils";

const Placements = () => {
  const student = [
    {
      img: IMAGES.photo,
      name: "Puja Bhole",
      company: "Power BI",
    },
    {
      img: IMAGES.photo,
      name: "Swapnil patil",
      company: "Software Testing",
    },
    {
      img: IMAGES.photo,
      name: "Vrushali Chaudhari",
      company: "Digital Marketing",
    },
    {
      img: IMAGES.photo,
      name: "Pragati khachane",
      company: "Digital Marketing",
    },
    {
      img: IMAGES.photo,
      name: "Kishor Patil",
      company: "Graphics Designer",
    },
    {
      img: IMAGES.photo,
      name: "Pratik Narkhede",
      company: "Web Developer",
    },

    {
      img: IMAGES.photo,
      name: "Shweta kharche",
      company: "Web Developer",
    },
    {
      img: IMAGES.photo,
      name: "Chetan patil",
      company: "Graphics Designer",
    },
    {
      img: IMAGES.photo,
      name: "Neha patil",
      company: "Web Developer",
    },
    {
      img: IMAGES.photo,
      name: "Chetan khachane",
      company: "Digital Marketing",
    },
  ];
  return (
    <>
      <div className="placement-section">
        <div className="container">
          <div className="placement-heading">
            <div>
              <h3>Placements</h3>
              <h5>Students we placed</h5>
            </div>
          </div>
          <div className="row">
            {student.map((s, idx) => (
              <div className="col-lg-3 col-md-4 col-sm-12">
                <div className="placement-col-wrapper">
                  <div className="placement-content">
                    <div className="profile-photo">
                      <img src={s.img} alt="profile photo" />
                    </div>
                    <div className="placement-data">
                      <p>{s.name} </p>
                      <p>{s.company}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Placements;
