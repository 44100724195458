import React from "react";
import "../../Assets/Styles/CertificationInDm.css"
import { IMAGES } from "../../utils";

const CertificateInDm = () => {
  const certificate = [
    { img: IMAGES.c1 },
    { img: IMAGES.c2 },
    { img: IMAGES.c3 },
    { img: IMAGES.c4 },
    { img: IMAGES.c5 },
    { img: IMAGES.c6 },
    { img: IMAGES.c7 },
    { img: IMAGES.c8 },
    { img: IMAGES.c9 },
    { img: IMAGES.c10 },
    { img: IMAGES.c11 },
    { img: IMAGES.c12 },
    { img: IMAGES.c13 },
    { img: IMAGES.c14 },
    { img: IMAGES.c15 },
  ];
  return (
    <>
      <section className="certificateSection">
        <div className="container">
          <div className="certificateHeading">
            <h3>15+ Certifications In Digital Marketing</h3>
          </div>
          <div className="row">
            {certificate.map((c, idx) => (
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="certificateWrapper">
                  <img src={c.img} className="certificate" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CertificateInDm;
