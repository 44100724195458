import React from "react";
import "../Assets/Styles/WebDevelopmentCrs.css";
import { IMAGES } from "../utils";
import { FaAsterisk } from "react-icons/fa";

const WebDevelopment = () => {
  return (
    <>
      <div className="webdev-wrapper">
        <div className="wat-is-web-dev-wrapper">
          <div className="container">
            <div className="wat-is-web-dev">
              <h3>What is Web Devevelopment?</h3>
              <p>
                Web development is the processes involved in designing,
                constructing, and managing websites and web applications that
                are accessible throughweb browsers. This field often
                incorporates elements such as Web Design, Programming, and
                Database Administration.
              </p>
              <p>
                Web development is closely linked to the task of designing and
                developing the features and functionality of websites. The term
                "Development" typically refers to the actual implementation of
                these elements, involving programming and coding of the sites.
              </p>
              <p>
                The fundamental tools utilized in web development include HTML
                (Hypertext Markup Language), CSS (Cascading Style Sheets),
                Javascript.However, there are various other software programs
                available to assist in managing or streamlining the website
                construction process, which would otherwise require coding from
                scratch.
              </p>
            </div>
          </div>
        </div>

        <div className="wat-webdev-do-wrapper">
          <div className="wat-web-dev-do-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="what-is-web-dev-do-wrapper">
                  <div className="wat-is-web-dev-do">
                    <h3>What Web Developer’ s Do?</h3>
                    <p>
                      Web developers play a crucial role in technology teams,
                      responsible for implementing the designs created by UX
                      designers. Their main tasks include converting graphical
                      mock-ups into web pages using HTML, CSS, and JavaScript
                      code. They handle the integration of various content types
                      such as text, images, animations, videos, and audio on
                      websites.
                    </p>
                    <p>
                      Web developers also focus on optimizing web page
                      performance and ensuring compliance with standards. They
                      establish communication between the user interface and
                      server using JavaScript and are capable of developing
                      basic web applications based on user requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img
                  src={IMAGES.watWebDevDo}
                  alt="WebDevImage"
                  className="what-devv-ddo-img"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="web-dev-syllabus">
          <div className="container">
            <hr className="webdev-hr-top" />
            <div className="web-dev-heading">
              <h3>Syllabus</h3>
              <p>This Syllabus Covers</p>
            </div>
            <div className="syllabus-wrapper">
              <ul>
                <li>
                  <FaAsterisk size={20} className="web-dev-syllabus-icon" />
                  HTML
                </li>
                <li>
                  {" "}
                  <FaAsterisk size={20} className="web-dev-syllabus-icon" />
                  CSS
                </li>
                <li>
                  {" "}
                  <FaAsterisk size={20} className="web-dev-syllabus-icon" />
                  JavaScript
                </li>
                <li>
                  {" "}
                  <FaAsterisk size={20} className="web-dev-syllabus-icon" />
                  Bootstrap
                </li>
                <li>
                  {" "}
                  <FaAsterisk size={20} className="web-dev-syllabus-icon" />
                  Ajax
                </li>
                <li>
                  {" "}
                  <FaAsterisk size={20} className="web-dev-syllabus-icon" />
                  JQuery
                </li>
              </ul>
            </div>
            <hr className="webdev-hr-bottom" />
          </div>
        </div>
      </div>
    </>
  );
};

export default WebDevelopment;
