import React from "react";
import "../../Assets/Styles/EligibilityInDm.css";
import { GiCheckMark } from "react-icons/gi";
const EligibilityInDm = () => {
  return (
    <>
      <section className="eligibilityWrapper">
        <div className="container">
          <div className="eligibilityTitle">
            <h3>Digital Marketing Course Eligibility -</h3>
          </div>
          <div className="eligibilitySection">
            <p>
              The eligibility criteria for a digital marketing course can vary
              depending on the institution or organization offering the course.
              However, in general, there are no strict prerequisites or
              educational qualifications required to enroll in a digital
              marketing course. These courses are typically open to individuals
              from various educational backgrounds and professional experiences.
            </p>
          </div>
          <hr className="eligibility-hr-line" />
          <div className="eligibilityTitle">
            <h3>Digital Marketing Job Opportunity -</h3>
          </div>
          <div className="eligibilitySection">
            <p>
              Digital marketing offers a wide range of job opportunities in
              today's digital-centric business landscape. As more businesses
              recognize the importance of having a strong online presence and
              reaching their target audience through digital channels, the
              demand for digital marketing professionals continues to grow.
            </p>
            <p>Here are some common job opportunities in digital marketing:</p>
          </div>

          <div className="row row-job-opp">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <ul className="eligibilitylist">
                    <li>
                      <GiCheckMark size={12} className="checkIcon" />
                      <p>Digital Marketing Executive</p>
                    </li>
                    <li>
                      <GiCheckMark size={12} className="checkIcon" />
                      <p>PPC Expert</p>
                    </li>
                    <li>
                      <GiCheckMark size={12} className="checkIcon" />
                      <p>SEO Expert</p>
                    </li>
                    <li>
                      <GiCheckMark size={12} className="checkIcon" />
                      <p>SEO Executive</p>
                    </li>
                    <li>
                      <GiCheckMark size={12} className="checkIcon" />
                      <p>Content Marketer</p>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <ul className="eligibilitylist">
                    <li>
                      {" "}
                      <GiCheckMark size={12} className="checkIcon" />
                      <p>Content Writer</p>
                    </li>
                    <li>
                      {" "}
                      <GiCheckMark size={12} className="checkIcon" />
                      <p>Social Media Expert</p>
                    </li>
                    <li>
                      {" "}
                      <GiCheckMark size={12} className="checkIcon" />
                      <p>Social Media Executive</p>
                    </li>
                    <li>
                      {" "}
                      <GiCheckMark size={12} className="checkIcon" />
                      <p>E-Marketing Executive</p>
                    </li>
                    <li>
                      {" "}
                      <GiCheckMark size={12} className="checkIcon" />
                      <p>Campaign Manager</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="downloadSyllabusBtn">
                <div>
                  <p>Check Out Our Syllabus here</p>
                  <div className="btnDownloadWrapper">
                    <button className="btnDwn">Download</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EligibilityInDm;
