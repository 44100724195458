import React from "react";
import "../../Assets/Styles/WhyDigitalMarketing.css";
import { IMAGES } from "../../utils";
import { TfiHandPointRight } from "react-icons/tfi";

const WhyDigitalMarketing = () => {
  return (
    <>
      <section className="digitalMarketing">
        <div className="container">
          <div className="digitalMarketingHeading">
            <h3>Why Digital Marketing?</h3>
          </div>
          <div className="digitalMarketing-top-Text">
            <p>
              There are many reasons why digital marketing has become essential
              for businesses of all sizes and industries.
              <br /> Here are a few of the key reasons why digital marketing is
              important:
            </p>
          </div>
          <div className="DMWrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="digitalMarketing-cards">
                  <div className="sub-digitalMarketing-cards">
                    <div className="DMPointsSubtitile">
                      <TfiHandPointRight size={25} className="DMIcon" />
                      <h5>Increased Reach</h5>
                    </div>

                    <p>
                      With digital marketing, businesses can reach a much larger
                      audience than traditional marketing channels. Digital
                      channels like social media, email, and search engines have
                      billions of users, allowing businesses to connect with
                      customers and prospects all over the world.
                    </p>
                  </div>
                  <div>
                    <img src={IMAGES.IncreasedReach} className="whyDm-img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="digitalMarketing-cards">
                  <div className="sub-digitalMarketing-cards">
                    <div className="DMPointsSubtitile">
                      <TfiHandPointRight size={25} className="DMIcon" />
                      <h5>Greater Targeting</h5>
                    </div>
                    <p>
                      Digital marketing allows businesses to target their
                      audience more precisely, based on factors like age,
                      location, interests, and behavior. This means that
                      businesses can create more personalized and relevant
                      messages that resonate with their target audience and
                      drive better results.
                    </p>
                  </div>
                  <div>
                    <img src={IMAGES.GreaterTargeting} className="whyDm-img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="digitalMarketing-cards">
                  <div className="sub-digitalMarketing-cards">
                    <div className="DMPointsSubtitile">
                      <TfiHandPointRight size={25} className="DMIcon" />
                      <h5>Cost-Effective</h5>
                    </div>
                    <p>
                      Digital marketing is often more cost-effective than
                      traditional marketing channels like TV, radio, and print.
                      Many digital marketing channels offer a range of pricing
                      options, from pay-per-click to subscription-based models,
                      allowing businesses to choose the option that best fits
                      their budget and goals
                    </p>
                  </div>
                  <div>
                    <img src={IMAGES.CostEffective} className="whyDm-img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="digitalMarketing-cards">
                  <div className="sub-digitalMarketing-cards">
                    <div className="DMPointsSubtitile">
                      <TfiHandPointRight size={25} className="DMIcon" />
                      <h5>Measurable Results</h5>
                    </div>
                    <p>
                      Digital marketing allows businesses to track and measure
                      their results more accurately than traditional marketing
                      channels. Businesses can use tools like Google Analytics
                      to track website traffic, conversions, and other metrics,
                      allowing them to optimize their campaigns and improve
                      their ROI over time.
                    </p>
                  </div>
                  <div>
                    <img src={IMAGES.MeasurableResults} className="whyDm-img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="digitalMarketing-cards">
                  <div className="sub-digitalMarketing-cards">
                    <div className="DMPointsSubtitile">
                      <TfiHandPointRight size={25} className="DMIcon" />
                      <h5>Flexibility and Agility</h5>
                    </div>
                    <p>
                      Digital marketing offers businesses greater flexibility
                      and agility in responding to market changes and customer
                      needs. Unlike traditional marketing channels, digital
                      marketing campaigns can be launched quickly and modified
                      in real-time based on customer feedback and market trends.
                    </p>
                  </div>
                  <div>
                    <img src={IMAGES.FlexibilityAndAgility} className="whyDm-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyDigitalMarketing;
