import React, { useState, useEffect } from "react";
import "../Assets/Styles/Courses.css";
import { IMAGES } from "../utils";
import { Link } from "react-router-dom";
import RegisterationPopUp from "../auth/RegistrationPopUp";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";

const Courses = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <Helmet>
        <title>
          Code Hub Technology offer the Best IT Training Courses in Sangvi, Pune
        </title>
        <meta
          name="description"
          content="Code Hub Technology is a Best IT training Courses in Sangvi, Pune. We provide online/offline courses offering Web Designing, Web Development, Full Stack Development, Digital Marketing, Graphics Designing, Python, Power BI, Business Analyst, Software Testing, Aptitude Course."
        />
        <meta
          name="keywords"
          content="Code HubTechnology IT Institute,IT Institude,Code Hub Technology institute,IT institute near me,Code HUb Technology IT Institute courses list,code Hub Technology academy,course,software Testing,web designe courses,Web Designing,Web Development,Full Stack Development,Digital Marketing, Graphics Designing,Python,power BI,Business Analyst,Software Testing,Aptitude Course,sangvi,pune"
        />
      </Helmet>

      <div className="setionCourse">
        <div className="course-image">
          <img src={IMAGES.courseLeft1} />
        </div>
        <div className="course-image1">
          <img src={IMAGES.courseLeft} />
        </div>
        <div className="course-image2">
          <img src={IMAGES.courseLeft1} />
        </div>
        <div className="course-image3">
          <img src={IMAGES.courseLeft} />
        </div>
        <div className="courseWrapper">
          <div className="container course-title-text">
            <h3>We Offer Best Course</h3>
            <p>
              At our company, we take pride in offering the best courses to help
              individuals enhance their skills and knowledge in various domains.
              Our courses are designed to provide comprehensive and practical
              learning experiences, equipping students with the expertise needed
              to succeed in today's competitive landscape.
            </p>
          </div>

          <div className="course-card-section">
            <div className="course-card-body">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Web Designing</h3>
                        <p>
                          Web Designing course covers a range of topics like
                          HTML, CSS, JavaScript, Web Design principles, User
                          Experience Design, and Responsive Design ..
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="/webdesign">Read More..</Link>
                      </p>
                      <img src={IMAGES.cr1} className="course-inner-image" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Web Development</h3>
                        <p>
                          The web development includes web design, web content
                          development, client /server side scripting and network
                          security configuration, among other tasks.
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="/webdevelopment">Read More..</Link>
                      </p>
                      <img src={IMAGES.cr4} className="course-inner-image" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Full Stack Development</h3>
                        <p>
                          Managing full application from start to end.This
                          involves understanding and working with various
                          programming languages and multiple technologies.
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="/fullstackdev">Read More..</Link>
                      </p>
                      <img
                        src={IMAGES.crFullStack}
                        className="course-inner-image"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Digital Marketing</h3>
                        <p>
                          Digital Marketing is the Online Marketing.It is the
                          way of connecting potential customers using internet
                          and other forms of digital communication.
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="/digitalmarketing">Read More..</Link>
                      </p>
                      <img src={IMAGES.cr2} className="course-inner-image" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Graphics Designing</h3>
                        <p>
                          Graphic design is the practice of visual
                          communication, using imagery to convey information and
                          concepts to a viewer.
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="/graphicsdesigning">Read More..</Link>
                      </p>
                      <img src={IMAGES.cr3} className="course-inner-image" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Python</h3>
                        <p>
                          Python is an interpreted, object-oriented, high-level
                          programming language with dynamic semantics. Its
                          high-level built in data structures.
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="">Read More..</Link>
                      </p>
                      <img
                        src={IMAGES.crPython}
                        className="course-inner-image"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Power bI</h3>
                        <p>
                          Power BI is a collection of software services, apps,
                          and connectors that work together to turn your
                          unrelated sources of data into coherent, visually
                          immersive, and interactive insights.
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="">Read More..</Link>
                      </p>
                      <img
                        src={IMAGES.crPowerBI}
                        className="course-inner-image"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Business Analyst</h3>
                        <p>
                          Business analysts use data to form business insights
                          and recommend changes in businesses and other
                          organizations.
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="">Read More..</Link>
                      </p>
                      <img src={IMAGES.crBA} className="course-inner-image" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Software Testing</h3>
                        <p>
                          Software testing is the process of evaluating and
                          verifying that a software product or application does
                          what it is supposed to do.
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="">Read More..</Link>
                      </p>
                      <img
                        src={IMAGES.crSoftTest}
                        className="course-inner-image"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Aptitude Course</h3>
                        <p>
                          This course consists of practice exercises for
                          Quantitative or Numerical and Verbal Ability.
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="">Read More..</Link>
                      </p>
                      <img
                        src={IMAGES.crAptiCourse}
                        className="course-inner-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="adjust-space-bg">
            <div className="adjust-space-bg-inner"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
