import React from "react";
import "../Assets/Styles/SatisfactionGuarenteed.css";
import { IMAGES } from "../utils";
import { Tb24Hours, TbMoneybag } from "react-icons/tb";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { BiBuildingHouse } from "react-icons/bi";

const SatisfactionGuarenteed = () => {
  return (
    <>
      <div className="satisfaction-guarantee">
        <div className="container">
          <div className="guarantee-heading">
            <h3>Satisfaction Guaranteed</h3>
            <h5>The best short-term program that offers a job guarantee</h5>
          </div>
          <div className="guarantee-section">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="gurantee-outer-wrapper">
                  <div className="guarantee-inner-wrapper-tr">
                    <div className="gurantee-image-box-tr">
                      <div className="sions-div">
                        <img src={IMAGES.sicon1} className="gurantee-icon-tr" />
                      </div>
                      <div className="guarantee-img-tr">
                        <img src={IMAGES.s1} alt="" />
                      </div>
                    </div>
                    <div className="guarantee-content-br">
                      <h5>24/7 Help Desk</h5>
                      <p>
                        We have opened our portal to take inquiries for any
                        course-related questions, and we promise to respond
                        quickly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="gurantee-outer-wrapper">
                  <div className="guarantee-inner-wrapper-bl">
                    <div className="guarantee-content-bl">
                      <h5>Money For a Quality and Value</h5>
                      <p>
                        Due to our philosophy of providing 100% job assistance
                        for each course till you land your ideal career,
                        everyone is welcome to apply for learning with quality.
                      </p>
                    </div>
                    <div className="guarantee-image-box-bl">
                      <div className="sions-div">
                        <img src={IMAGES.sicon2} className="gurantee-icon-bl" />
                      </div>
                      <div className="guarantee-img-bl">
                        <img src={IMAGES.s2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Placement Department */}
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="gurantee-outer-wrapper">
                  <div className="guarantee-inner-wrapper-br">
                    <div className="guarantee-image-box-br">
                      <div className="sions-div">
                        <img src={IMAGES.sicon3} className="gurantee-icon-br" />
                      </div>
                      <div className="guarantee-img-br">
                        <img src={IMAGES.s3} alt="" />
                      </div>
                    </div>
                    <div className="guarantee-content-tr">
                      <h5> Placement Department</h5>
                      <p>
                        There is a separate placement department here. Whose
                        ongoing activity involves campus recruitment and company
                        partnerships.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="gurantee-outer-wrapper">
                  <div className="guarantee-inner-wrapper-tl">
                    <div className="guarantee-content-tl">
                      <h5>In-House Company Benefits</h5>
                      <p>
                        Because we have a US-based internal company beneath the
                        roof of our IT education centre, candidates will get
                        access to a live project working environment.
                      </p>
                    </div>
                    <div className="guarantee-image-box-tl">
                      <div className="sions-div">
                        <img src={IMAGES.sicon4} className="gurantee-icon-tl" />
                      </div>
                      <div className="guarantee-img-tl">
                        <img src={IMAGES.s4} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SatisfactionGuarenteed;
