import React, { useState } from "react";
import "../Assets/Styles/RequestCallBack.css";
import { GiCheckMark } from "react-icons/gi";
const RequestCallBack = () => {
  const [state, setState] = useState({
    ContactID: 0,
    FullName: "",
    Email: "",
    Mobile: "",
    SelectOption: "",
    OnlineTraining: "",
    ClassTraining: "",
    CreatedBy: 1,
    errors: [],
  });

  const [onlineTraining, setOnlineTraining] = useState(false);
  const [classTraining, setClassTraining] = useState(false);

  const handleChnge = (e) => {
    if (e.target.value === "Online Training") {
      setOnlineTraining(true);
      setClassTraining(false);
      setState((state.ClassTraining = ""));
    }
    if (e.target.value === "Class Training") {
      setOnlineTraining(false);
      setClassTraining(true);
      setState((state.OnlineTraining = ""));
    }
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });
  };
  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!state.FullName) {
      IsValid = false;
      errors["FullName"] = "Full Name Is Required";
    } else if (state.FullName.length < 2) {
      IsValid = false;
      errors["FullName"] = "Must Be 15 Characters ";
    } else if (!state.FullName.match(regex_name)) {
      IsValid = false;
      errors["FullName"] = "Please Enter Valid Name";
    } else {
      errors["FullName"] = "";
    }

    if (!state.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile number Is Required";
    } else if (state.Mobile.length < 10 || state.Mobile.length > 10) {
      IsValid = false;
      errors["Mobile"] = "Mobile Number Must Have 10 Digits";
    } else if (!state.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }

    if (!state.Email) {
      IsValid = false;
      errors["Email"] = "Email ID Is Required";
    } else if (!state.Email.match(regex_email)) {
      IsValid = false;
      errors["Email"] = "Please Enter valid Email id";
    } else {
      errors["Email"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };
  const handleSubmit = async () => {
    console.log("submit-->", state);
    if (validate()) {
      // await iucontact(state).then((res) => {
      //   console.log("contact--->", res[0]);
      //   if (res.length == 1) {
      //     alert("your message successfully sent to us...!!");
      //     clearState();
      //   }
      // });
      alert("done");
    } else {
      alert("Please Enter Proper Details..!");
    }
  };

  return (
    <>
      <div className="requestCallBack-section">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-lg-6">
                <div className="requestCallBack">
                  <h3>Request Call Back</h3>
                </div>
                <div className="requestCallBackLeft">
                  {" "}
                  <div className="req-inputWrapper">
                    <input
                      type="text"
                      className=" req-form-control"
                      placeholder="Enter Full Name"
                      aria-label="Full Name"
                      name="FullName"
                      value={state.FullName}
                      onChange={handleChnge}
                    />
                  </div>
                  {state.errors ? (
                    <p className="req-errors">{state.errors.FullName}</p>
                  ) : null}
                  <div className="req-inputWrapper">
                    <input
                      type="text"
                      className=" req-form-control"
                      placeholder="Enter Your Email"
                      aria-label="Email"
                      name="Email"
                      value={state.Email}
                      onChange={handleChnge}
                    />
                  </div>
                  {state.errors ? (
                    <p className="req-errors">{state.errors.Email}</p>
                  ) : null}
                  <div className="req-inputWrapper">
                    <input
                      type="number"
                      className=" req-form-control"
                      placeholder="Enter Mobile Number"
                      aria-label="mobile number"
                      maxLength={10}
                      name="Mobile"
                      value={state.Mobile}
                      onChange={handleChnge}
                    />
                  </div>
                  {state.errors ? (
                    <p className="req-errors">{state.errors.Mobile}</p>
                  ) : null}
                  <div className="req-inputWrapper">
                    <select
                      className="req-form-select"
                      name="SelectOption"
                      onChange={handleChnge}
                      value={state.SelectOption}
                    >
                      <option>Select Course Type</option>
                      <option value="Online Training">Online Training</option>
                      <option value="Class Training">Classroom Training</option>
                    </select>
                  </div>
                  {onlineTraining ? (
                    <div className="req-inputWrapper">
                      <select
                        className="req-form-select"
                        name="OnlineTraining"
                        onChange={handleChnge}
                        value={state.OnlineTraining}
                      >
                        <option>Select Course Type</option>

                        <option value="Web Design">Web Design</option>
                        <option value="Web development">Web development</option>
                        <option value="Full Stack Development">
                          Full Stack Development
                        </option>
                        <option value="Digital Marketing">
                          Digital Marketing
                        </option>
                        <option value="Graphics Design">Graphics Design</option>
                        <option value="Python ">Python </option>
                        <option value="Power BI">Power BI</option>
                        <option value="Business Analyst">
                          Business Analyst
                        </option>
                        <option value="Software Testing">
                          Software Testing
                        </option>
                        <option value="Aptitude Courses">
                          Aptitude Courses
                        </option>
                      </select>
                    </div>
                  ) : null}
                  {classTraining ? (
                    <div className="req-inputWrapper">
                      <select
                        className="req-form-select"
                        name="ClassTraining"
                        onChange={handleChnge}
                        value={state.ClassTraining}
                      >
                        <option>Select Course Type</option>
                        <option value="Web Design">Web Design</option>
                        <option value="Web development">Web development</option>
                        <option value="Full Stack Development">
                          Full Stack Development
                        </option>
                        <option value="Digital Marketing">
                          Digital Marketing
                        </option>
                        <option value="Graphics Design">Graphics Design</option>
                        <option value="Python ">Python </option>
                        <option value="Power BI">Power BI</option>
                        <option value="Business Analyst">
                          Business Analyst
                        </option>
                        <option value="Software Testing">
                          Software Testing
                        </option>
                        <option value="Aptitude Courses">
                          Aptitude Courses
                        </option>
                      </select>
                    </div>
                  ) : null}
                  {/* <div className="req-btn">
                    <button onClick={handleSubmit}>Request Call Back</button>
                  </div> */}
                  <div className="req-btn">
                    <div className="blue-green-btn">
                      <button onClick={handleSubmit}>
                        <span className="btn-text">Request Call Back</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="requestCallBackRight">
                  <div className="courseDescription-top">
                    <h3>
                      You are seeking job ???
                      <br />
                      Join us ..!!
                      <br />
                      Get <span>Assured Job</span> .
                    </h3>
                  </div>
                  <div className="req-courseDescription-bottom">
                    <div className="req-lowFees">
                      <GiCheckMark size={15} className="req-checkIcon" />
                      <p>Lowest Course Fees</p>
                    </div>
                    <div className="req-studyMaterial">
                      <GiCheckMark size={15} className="req-checkIcon" />
                      <p>Free Study Material</p>
                    </div>

                    <div className="req-placement">
                      {" "}
                      <GiCheckMark size={15} className="req-checkIcon" />
                      <p>100% Placement Assistance</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestCallBack;
