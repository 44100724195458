import React, { useState } from "react";
import "../../Assets/Styles/GraphicsDesign/WhyGd.css";
import { IMAGES } from "../../utils";
import {
  RiNumber1,
  RiNumber2,
  RiNumber3,
  RiNumber4,
  RiNumber5,
} from "react-icons/ri";

const WhyGd = () => {
  const [s1state, setS1State] = useState(true);
  const [s2state, setS2State] = useState(false);
  const [s3state, setS3State] = useState(false);
  const [s4state, setS4State] = useState(false);
  const [s5state, setS5State] = useState(false);

  const onMouseHover_s1 = () => {
    setS1State(true);
    setS2State(false);
    setS3State(false);
    setS4State(false);
    setS5State(false);
  };

  const onMouseHover_s2 = () => {
    setS1State(false);
    setS2State(true);
    setS3State(false);
    setS4State(false);
    setS5State(false);
  };

  const onMouseHover_s3 = () => {
    setS1State(false);
    setS2State(false);
    setS3State(true);
    setS4State(false);
    setS5State(false);
  };

  const onMouseHover_s4 = () => {
    setS1State(false);
    setS2State(false);
    setS3State(false);
    setS4State(true);
    setS5State(false);
  };

  const onMouseHover_s5 = () => {
    setS1State(false);
    setS2State(false);
    setS3State(false);
    setS4State(false);
    setS5State(true);
  };

  return (
    <>
      <div className="sectionWhyGd">
        <div className="whyGdWrapper">
          {s1state ? (
            <div className="whyGdImgWrapper">
              <img src={IMAGES.VisualCommunication} className="whyGdImg" alt="" />
            </div>
          ) : null}

          {s2state ? (
            <div className="whyGdImgWrapper">
              <img src={IMAGES.BrandingAndMarketing} className="whyGdImg" alt="" />
            </div>
          ) : null}

          {s3state ? (
            <div className="whyGdImgWrapper">
              <img src={IMAGES.AestheticAppeal} className="whyGdImg" alt="" />
            </div>
          ) : null}

          {s4state ? (
            <div className="whyGdImgWrapper">
              <img src={IMAGES.Professionalism} className="whyGdImg" alt="" />
            </div>
          ) : null}

          {s5state ? (
            <div className="whyGdImgWrapper">
              <img src={IMAGES.Creativity} className="whyGdImg" alt="" />
            </div>
          ) : null}

          <div className="whyGdcontent">
            <div className="whyGdTitle">
              <h3>Why Graphics Design?</h3>
              <p>
                Graphics design is important for many reasons, both practical
                and artistic. Here are a few reasons why graphics design is a
                valuable skill:
              </p>
            </div>
            <div className="gdcontent">
              <ul className="gdcontent-list">
                <li onMouseOver={onMouseHover_s1}>
                  <div className="srNo">
                    <RiNumber1 size={25} className="srNoIcon" />
                    <h5>Visual Communication</h5>
                  </div>

                  <p>
                    Graphics design is an effective way to communicate a message
                    visually, whether it's promoting a product or service,
                    creating a brand identity, or conveying information. A
                    well-designed visual can make a strong impact on the viewer
                    and help to convey the message in a clear and concise way.
                  </p>

                  <hr className="gdHr" />
                </li>

                <li onMouseOver={onMouseHover_s2}>
                  <div className="srNo">
                    <RiNumber2 size={25} className="srNoIcon" />
                    <h5>Branding and Marketing</h5>
                  </div>

                  <p>
                    Graphics design plays a critical role in branding and
                    marketing, as it helps to create a visual identity for a
                    business or organization. A strong brand identity can help
                    to build recognition, trust, and loyalty among customers,
                    and can differentiate a business from its competitors.
                  </p>
                  <hr className="gdHr" />
                </li>

                <li onMouseOver={onMouseHover_s3}>
                  <div className="srNo">
                    <RiNumber3 size={25} className="srNoIcon" />
                    <h5>Aesthetic Appeal</h5>
                  </div>

                  <p>
                    Good graphics design is aesthetically pleasing and can
                    create an emotional connection with the viewer. A
                    well-designed visual can make a lasting impression and leave
                    a positive impression on the viewer.
                  </p>
                  <hr className="gdHr" />
                </li>

                <li onMouseOver={onMouseHover_s4}>
                  <div className="srNo">
                    <RiNumber4 size={25} className="srNoIcon" />
                    <h5>Professionalism</h5>
                  </div>

                  <p>
                    Good graphics design is an essential component of
                    professionalism in many industries, from business to
                    healthcare to education. A well-designed visual can help to
                    create a professional image and convey competence and
                    expertise.
                  </p>
                  <hr className="gdHr" />
                </li>

                <li onMouseOver={onMouseHover_s5}>
                  <div className="srNo">
                    <RiNumber5 size={25} className="srNoIcon" />
                    <h5>Creativity</h5>
                  </div>

                  <p>
                    Graphics design is a creative field that allows designers to
                    express their creativity and explore different ideas and
                    concepts. It offers the opportunity to work with a range of
                    media and tools, and to experiment with different design
                    elements and techniques.
                  </p>
                  <hr className="gdHr" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyGd;
