import React from "react";
import "../../Assets/Styles/ScopeInDm.css";
const ScopeInDm = () => {
  return (
    <>
      <section className="ScopeInDm">
        <div className="container">
          <div className="ScopeInDmTitle">
            <h3>Scope of Digital Marketing</h3>
          </div>
          <div className="scopOfDm-top-text">
            <p>
              The scope of digital marketing is constantly growing, as more and
              more businesses realize the importance of digital channels for
              reaching and engaging with their target audience. Here are a few
              of the key areas where digital marketing is having a significant
              impact:
            </p>
          </div>
          <div className="scopeOfDMWrapper">
            <div className="scopeOfDMSection">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 scope-cards-outer">
                  <div className="scopeOfDMPoints overlay-one">
                    <div className="overlay-two">
                      <h5 className="scopeOfDM-Heading">E-commerce</h5>
                      <p className="scopeOfDM-text">
                        Digital marketing is an essential part of e-commerce, as
                        businesses need to use digital channels to reach
                        customers and promote their products and services online
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 scope-cards-outer">
                  <div className="scopeOfDMPoints overlay-one">
                    <div className="overlay-two">
                      <h5 className="scopeOfDM-Heading">
                        Branding and Awareness
                      </h5>

                      <p className="scopeOfDM-text">
                        Digital marketing allows businesses to build brand
                        awareness and promote their products and services to a
                        much larger audience than traditional marketing
                        channels.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 scope-cards-outer">
                  <div className="scopeOfDMPoints overlay-one">
                    <div className="overlay-two">
                      <h5 className="scopeOfDM-Heading">
                        Lead Generation and Sales
                      </h5>

                      <p className="scopeOfDM-text">
                        Digital marketing is an effective way to generate leads
                        and drive sales, as businesses can use a range of
                        tactics like search engine optimization, PPC
                        advertising, and social media marketing to reach and
                        engage with potential customers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 scope-cards-outer">
                  <div className="scopeOfDMPoints overlay-one">
                    <div className="overlay-two">
                      <h5 className="scopeOfDM-Heading">
                        Customer Engagement and Loyalty
                      </h5>
                      <p className="scopeOfDM-text">
                        Digital marketing allows businesses to build lasting
                        relationships with their customers, by providing them
                        with relevant and personalized content that meets their
                        needs and interests.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 scope-cards-outer">
                  <div className="scopeOfDMPoints overlay-one">
                    <div className="overlay-two">
                      <h5 className="scopeOfDM-Heading">
                        Analytics and Insights
                      </h5>

                      <p className="scopeOfDM-text">
                        Digital marketing provides businesses with a wealth of
                        data and insights that can help them make informed
                        decisions and optimize their campaigns over time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ScopeInDm;
