import React, { useState } from "react";
import "../Assets/Styles/Modals/GetGuidanceModal.css";
import { IMAGES } from "../utils";
import { MdClose } from "react-icons/md";

const GetGuidanceModal = ({ GuidanceShow, setGuidanceShow }) => {
  const [guidanceState, setGuidanceState] = useState({
    ContactID: 0,
    FullName: "",
    Email: "",
    Mobile: "",
    Education: "",
    Subject: "",
    CreatedBy: 1,
    errors: [],
  });
  const handleGuidanceChange = (e) => {
    setGuidanceState({
      ...guidanceState,
      [e.target.name]: e.target.value,
      errors: { ...guidanceState.errors, [e.target.name]: "" },
    });
  };
  const clearStates = () => {
    setGuidanceState({
      ContactID: 0,
      FullName: "",
      Email: "",
      Mobile: "",
      Education: "",
      Subject: "",
      CreatedBy: 1,
      errors: "",
    });
  };
  const validateGuidance = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!guidanceState.FullName) {
      IsValid = false;
      errors["FullName"] = "Full Name Is Required";
    } else if (guidanceState.FullName.length < 2) {
      IsValid = false;
      errors["FullName"] = "Must Be 15 Characters ";
    } else if (!guidanceState.FullName.match(regex_name)) {
      IsValid = false;
      errors["FullName"] = "Please Enter Valid Name";
    } else {
      errors["FullName"] = "";
    }

    if (!guidanceState.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile number Is Required";
    } else if (
      guidanceState.Mobile.length < 10 ||
      guidanceState.Mobile.length > 10
    ) {
      IsValid = false;
      errors["Mobile"] = "Mobile Number Must Have 10 Digits";
    } else if (!guidanceState.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }

    if (!guidanceState.Email) {
      IsValid = false;
      errors["Email"] = "Email ID Is Required";
    } else if (!guidanceState.Email.match(regex_email)) {
      IsValid = false;
      errors["Email"] = "Please Enter valid Email id";
    } else {
      errors["Email"] = "";
    }

    setGuidanceState({
      ...guidanceState,
      errors: errors,
    });
    return IsValid;
  };
  const GuidanceHandleClose = () => {
    setGuidanceShow(false);
    clearStates();
  };

  const handleGuidanceSubmit = () => {
    if (validateGuidance()) {
      clearStates();
      setGuidanceShow(false);
      alert("done");
    } else {
      alert("Please Enter Proper Details ");
    }
  };
  return (
    <>
      <div
        className="modal fade guidance-model"
        id="staticBackdropGuidance"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog guidance-modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="guidance-btn-close "
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={GuidanceHandleClose}
            >
              {" "}
              <MdClose size={25} className="guidance-btn-close-icon" />
            </button>

            <div className="modal-body  guidance-modal-body">
              <div className="row ">
                <div className="col-lg-5">
                  <div className="get-career-guidance-img-wrapper">
                    <img src={IMAGES.GetCareerGuidance} alt="" className="animate__animated animate__slideInLeft" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="get-career-guidance-section">
                    <h5
                      className="modal-title get-career-guidance-modal-title"
                      id="staticBackdropLabel"
                    >
                      Get Career Guidance
                    </h5>
                    <p className="get-career-guidance-modal-text">
                      {/* Get Expert Career Advice */}
                      If you want to get career guidance then fill up the form
                      below
                    </p>
                    <div className="w-100 mb-3 px-2">
                      <input
                        type="text"
                        className="reg-form-control"
                        placeholder="Full Name"
                        autoComplete="off"
                        name="FullName"
                        value={guidanceState.FullName}
                        onChange={handleGuidanceChange}
                      />
                      {guidanceState.errors ? (
                        <p className="req-errors">
                          {guidanceState.errors.FullName}
                        </p>
                      ) : null}
                    </div>

                    <div className="w-100 mb-3 px-2">
                      <input
                        type="number"
                        className="reg-form-control"
                        placeholder="Mobile No."
                        autoComplete="off"
                        name="Mobile"
                        value={guidanceState.Mobile}
                        onChange={handleGuidanceChange}
                      />
                      {guidanceState.errors ? (
                        <p className="req-errors">
                          {guidanceState.errors.Mobile}
                        </p>
                      ) : null}
                    </div>

                    <div className="w-100 mb-3 px-2">
                      <input
                        type="email"
                        className="reg-form-control"
                        placeholder="Email ID"
                        autoComplete="off"
                        name="Email"
                        value={guidanceState.Email}
                        onChange={handleGuidanceChange}
                      />
                      {guidanceState.errors ? (
                        <p className="req-errors">
                          {guidanceState.errors.Email}
                        </p>
                      ) : null}
                    </div>

                    <div className="w-100 mb-3 px-2">
                      <input
                        type="text"
                        className="reg-form-control"
                        placeholder="Education"
                        autoComplete="off"
                        name="Education"
                        value={guidanceState.Education}
                        onChange={handleGuidanceChange}
                      />
                    </div>

                    <div className="w-100 mb-3 px-2">
                      <textarea
                        className="reg-form-control"
                        placeholder="Subject"
                        rows={2}
                        autoComplete="off"
                        name="Subject"
                        value={guidanceState.Subject}
                        onChange={handleGuidanceChange}
                      ></textarea>
                    </div>

                    <div className="w-100 px-2 guidance-btn-wrapper">
                      <div className="green-blue-btn">
                        <button onClick={handleGuidanceSubmit}>
                          <span className="btn-text">Get Guidance</span>
                        </button>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetGuidanceModal;
