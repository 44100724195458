import React from "react";
import "../../Assets/Styles/Services/MobileAppdevelopment.css";
import { IMAGES } from "../../utils";
import { GoArrowSmallRight } from "react-icons/go";
import { GiCheckMark, GiWireframeGlobe } from "react-icons/gi";
import { FaRegLightbulb } from "react-icons/fa";
import { MdOutlineDeveloperMode } from "react-icons/md";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { GrVmMaintenance } from "react-icons/gr";
import { SiVitest } from "react-icons/si";
import RegisterationPopUp from "../../auth/RegistrationPopUp";
import ServicesContact from "../../auth/ServicesContact";
import { useState } from "react";
import { useEffect } from "react";
const MobileAppDevelopment = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  const leftInnovation = [
    { text: "React Native App Development" },
    { text: "Android App Development" },
    { text: "Hybrid App Development" },
    { text: "App prototyping & strategy" },
    { text: "UI/UX Design" },
  ];

  const rightInnovation = [
    { text: "App Architecure" },
    { text: "Application Maintaince Supports" },
    { text: "Build optimized apps" },
    { text: "Enterprise Application Development" },
    { text: "E-Commerce, B2B, B2C" },
  ];

  const innovationCard = [
    {
      imgCard: IMAGES.MobileInnoCard,
      text: "Custom Mobile Application Development",
    },
    {
      imgCard: IMAGES.MobileInnoCard,
      text: "Back-end Development",
    },
    {
      imgCard: IMAGES.MobileInnoCard,
      text: "Integration with existing enterprise services and data",
    },
    {
      imgCard: IMAGES.MobileInnoCard,
      text: "Integrating app with BLE",
    },
    {
      imgCard: IMAGES.MobileInnoCard,
      text: "App Prototype and strategy",
    },
    {
      imgCard: IMAGES.MobileInnoCard,
      text: "App Support and Maintaince",
    },
    {
      imgCard: IMAGES.MobileInnoCard,
      text: "Framework Development",
    },
    {
      imgCard: IMAGES.MobileInnoCard,
      text: "Multilingual apps",
    },
    {
      imgCard: IMAGES.MobileInnoCard,
      text: "Enterprise Application Development",
    },
  ];
  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="section-mobile-app">
        {/* BANNER */}
        <div className="mobile-app-banner">
          {/* <img src={IMAGES.MobileAppBanner} alt="" /> */}

          <p className="mb-banner-title">
           Mobile Applications
          </p>
        </div>

        {/* Process */}
        <div className="process">
          <div className="container">
            <div className="process-para">
              <h3>Mobile App Development</h3>
              <p>
                Mobile app development is the process of creating software
                applications specifically designed to run on mobile devices,
                such as smartphones and tablets. It involves several stages,
                including planning, designing, development, testing, and
                deployment.
              </p>

              <p>Here are the key steps involved in mobile app development:</p>
            </div>
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="process-steps-outer-wrapper">
                  <div className="process-steps-inner-wrapper">
                    <h5>
                      <FaRegLightbulb size={25} /> Idea and Planning:
                    </h5>
                    <p>
                      Define the purpose and objectives of your app. Research
                      the target audience, competition, and market trends. Plan
                      the features, functionalities, and overall scope of the
                      app.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="process-img-wrapper">
                  <img src={IMAGES.MobileIdea} alt="" />
                  <div className="mobile-round-img-shadow"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="process-img-wrapper">
                  <img src={IMAGES.MobileWireframing} alt="" />
                  <div className="mobile-round-img-shadow"></div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="process-steps-outer-wrapper">
                  <div className="process-steps-inner-wrapper">
                    <h5>
                      {" "}
                      <GiWireframeGlobe size={25} /> Wireframing and Design:{" "}
                    </h5>
                    <p>
                      Create a wireframe or prototype of the app's user
                      interface (UI) and user experience (UX) design. This helps
                      visualize the app's layout, navigation, and interactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="process-steps-outer-wrapper">
                  <div className="process-steps-inner-wrapper">
                    <h5>
                      {" "}
                      <MdOutlineDeveloperMode size={25} /> Development:
                    </h5>
                    <p>
                      Choose the platform(s) for your app, such as iOS (Apple)
                      or Android. Based on the platform, select the appropriate
                      programming languages and frameworks (e.g., Swift or
                      Objective-C for iOS, Java or Kotlin for Android). Develop
                      the app's front-end and back-end, including coding,
                      integrating APIs, and implementing features.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="process-img-wrapper">
                  <img src={IMAGES.MobileDevelopment} alt="" />
                  <div className="mobile-round-img-shadow"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="process-img-wrapper">
                  <img src={IMAGES.MobileTesting} alt="" />
                  <div className="mobile-round-img-shadow"></div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="process-steps-outer-wrapper">
                  <div className="process-steps-inner-wrapper">
                    <h5>
                      {" "}
                      <SiVitest size={25} /> Testing:
                    </h5>
                    <p>
                      Perform various types of testing, including functional
                      testing (ensuring all features work as intended),
                      compatibility testing (verifying app performance across
                      different devices and operating systems), and usability
                      testing (testing the app's ease of use).
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="process-steps-outer-wrapper">
                  <div className="process-steps-inner-wrapper">
                    <h5>
                      {" "}
                      <AiOutlineDeploymentUnit size={25} /> Deployment:
                    </h5>
                    <p>
                      Prepare your app for release by creating developer
                      accounts on app stores (e.g., Apple App Store, Google Play
                      Store). Follow their guidelines for app submission,
                      including providing app descriptions, screenshots, and
                      icons. Once approved, publish your app to the respective
                      app stores.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="process-img-wrapper">
                  <img src={IMAGES.MobileDeployment} alt="" />
                  <div className="mobile-round-img-shadow"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="process-img-wrapper">
                  <img src={IMAGES.MobileMaintenance} alt="" />
                  <div className="mobile-round-img-shadow"></div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="process-steps-outer-wrapper">
                  <div className="process-steps-inner-wrapper">
                    <h5>
                      {" "}
                      <GrVmMaintenance size={25} /> Maintenance and Updates:
                    </h5>
                    <p>
                      Continuously monitor your app's performance, gather user
                      feedback, and address any bugs or issues. Regularly update
                      your app with new features, improvements, and security
                      patches to enhance the user experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Innovative And Customized Mobile App Development Company */}
        <div className="mobile-app">
          <div className="innovation">
            <div className="innovation-section">
              <div className="innovation-heading">
                <h3>
                  Innovative And Customized Mobile App Development Company
                </h3>
                <p>
                  With one of the most rigorous and current training curricula,
                  Code Hub Technology is ranked as one of the mobile application
                  development training institutes in Pune. We are not just the
                  chosen training centre for mobile technologies for students,
                  but also for institutions and businesses. Mobile app
                  development refers to the process of creating software
                  applications that run on mobile devices such as smartphones
                  and tablets. It involves designing, building, testing, and
                  deploying applications for mobile platforms like iOS (Apple)
                  and Android (Google).
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="innovation-outer-wrapper">
                    {leftInnovation?.map((i, idx) => (
                      <div className="innovation-strip">
                        <GoArrowSmallRight size={25} color="#dd1717" />
                        <p>{i.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="innovation-mb">
                    <img src={IMAGES.MobileAppSingle} alt="" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="innovation-outer-wrapper">
                    {rightInnovation?.map((i, idx) => (
                      <div className="innovation-strip">
                        <GoArrowSmallRight size={25} color="#dd1717" />
                        <p>{i.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="innovation-cards">
                <div className="row">
                  {innovationCard?.map((ic, idx) => (
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="innovation-card-outer-wrapper">
                        <div className="innovation-card-inner-wrapper">
                          <div className="innovation-main-card">
                            <div className="innovation-card-icon">
                              <img src={ic.imgCard} alt="" />
                            </div>
                            <p>{ic.text}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* IOS MOBILE INFO */}
        <div className="ios-section">
          <div className="container">
            <div className="row ios-flex">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="ios-inner-wrapper">
                  <h5>CUSTOM REACT NATIVE SOLUTIONS</h5>
                  <h3>React Native Development Services</h3>
                  <p>
                    React Native development services refer to the services
                    provided by software development companies or agencies that
                    specialize in creating mobile applications using the React
                    Native framework. React Native is a popular open-source
                    framework developed by Facebook that allows developers to
                    build mobile apps using JavaScript and reusable components,
                    which can be deployed on both iOS and Android platforms.
                  </p>
                  <p>
                    Here are some key React Native development services that are
                    typically offered:
                  </p>
                  <ul>
                    <li>
                      <GiCheckMark size={20} className="ios-check-icon" />
                      <div className="ios-list">
                        <p>Mobile App Development</p>
                      </div>
                    </li>
                    <li>
                      <GiCheckMark size={20} className="ios-check-icon" />
                      <div className="ios-list">
                        <p>Cross-platform Development</p>
                      </div>
                    </li>
                    <li>
                      <GiCheckMark size={20} className="ios-check-icon" />
                      <div className="ios-list">
                        <p>Custom App Development</p>
                      </div>
                    </li>
                    <li>
                      <GiCheckMark size={20} className="ios-check-icon" />
                      <div className="ios-list">
                        <p>UI/UX Desige</p>
                      </div>
                    </li>
                    <li>
                      <GiCheckMark size={20} className="ios-check-icon" />
                      <div className="ios-list">
                        <p>App Performance Optimization</p>
                      </div>
                    </li>
                    <li>
                      <GiCheckMark size={20} className="ios-check-icon" />
                      <div className="ios-list">
                        <p> Integration Services</p>
                      </div>
                    </li>
                    <li>
                      <GiCheckMark size={20} className="ios-check-icon" />
                      <div className="ios-list">
                        <p>Maintenance and Support</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="ios-mb-img">
                  <img src={IMAGES.MobileAndroid} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Android MOBILE INFO */}
        {/* <div className="android-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="android-mb-img">
              <img src={IMAGES.MobileAndroid} alt="" />
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="android-inner-wrapper">
              <h5>CUSTOM ANDROID MOBILE SOLUTIONS</h5>
              <h3>Android App Development Services</h3>
              <p>
                Android is the most popular mobile operating system, as
                Appristine offers contemporary mobile application development
                for this operating system. Our team specializes in developing
                cross-platform Android applications. Find more about our
                service below.
              </p>
              <ul>
                <li>
                  <GiCheckMark size={20} className="android-check-icon" />
                  <div className="android-list">
                    <p>Low-cost Android application development solution</p>
                  </div>
                </li>
                <li>
                  <GiCheckMark size={20} className="android-check-icon" />
                  <div className="android-list">
                    <p>
                      Consult with us to develop a productive app for your
                      business
                    </p>
                  </div>
                </li>
                <li>
                  <GiCheckMark size={20} className="android-check-icon" />
                  <div className="android-list">
                    <p>
                      Revamp the user experience of your existing Android app
                    </p>
                  </div>
                </li>
                <li>
                  <GiCheckMark size={20} className="android-check-icon" />
                  <div className="android-list">
                    <p>
                      We develop apps that assure a high ROI (Return on
                      Investment)
                    </p>
                  </div>
                </li>
                <li>
                  <GiCheckMark size={20} className="android-check-icon" />
                  <div className="android-list">
                    <p>
                      Standard practices for developing agile Android apps
                    </p>
                  </div>
                </li>
              </ul>
              <p>
                Feel free to contact us for quotes on developing Android apps!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> */}

        {/* START_TO_END */}
        <div className="mobile-app">
          <div className="start-to-end">
            <div className="">
              <div className="row start-to-end-flex">
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="mobility-design">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="start-outer-wrapper">
                          <div className="start-inner-wrapper">
                            <h5>Ideation and Conceptualization</h5>
                            <p>
                              This phase involves brainstorming and refining the
                              app idea.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="start-outer-wrapper">
                          <div className="start-inner-wrapper">
                            <h5>UI/UX Design</h5>
                            <p>
                              In this stage, the app's user interface (UI) and
                              user experience (UX) are designed.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="native-monitor">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="start-outer-wrapper">
                          <div className="start-inner-wrapper">
                            <h5>Development</h5>
                            <p>
                              The development team starts coding the app based
                              on the finalized designs.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="start-outer-wrapper">
                          <div className="start-inner-wrapper">
                            <h5>Quality Assurance and Testing</h5>
                            <p>
                              Testing is a critical phase to identify and
                              resolve any bugs, glitches, or usability issues.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="mobile-app-wrapper-spacing">
                    <div className="start-to-end-heading">
                      <h3>Start-to-end app development agency</h3>
                      <p>
                        Start-to-end app development refers to the complete
                        process of creating a mobile application, from the
                        initial concept and design to development, testing,
                        deployment, and ongoing maintenance. A start-to-end app
                        development service provider offers comprehensive
                        solutions to handle every stage of the app development
                        lifecycle.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ServicesContact />
      </div>
    </>
  );
};

export default MobileAppDevelopment;
