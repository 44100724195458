import React from "react";
import "../Assets/Styles/Blogs.css";
import { IMAGES } from "../utils";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";

const Blogs = () => {
  const navigate = useNavigate();
  const HandleBlog = () => {
    navigate("/ViewBlog");
  };

  return (
    <>
      <div className="blogs">
        <div className="container">
          <div className="blogs-heading">
            <h3>Blogs</h3>
            <h5>
              To learn more about Code Hub Technology and its products, as well
              as to get industry insights, real-time solutions, and other
              benefits, read more about it.
            </h5>
          </div>
          <div className="blogs-section">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="blogs-outer-wrapper">
                  <div className="blogs-inner-wrapper">
                    <div className="blogs-img-date-wrapper">
                      <div className="blogs-img">
                        <img src={IMAGES.bgRequestcallBack} alt="" />
                      </div>
                      <div className="blog-date-wrapper">
                        <MdOutlineCalendarMonth size={30} />
                        <p>May 12, 2023</p>
                      </div>
                    </div>
                    <div className="blogs-para">
                      <h4>
                        Digital marketing is essential for small companies
                      </h4>
                      <p>
                        You're a company owner? Super! wait, wait. Have you ever
                        been thinking where you're on the market? ...
                      </p>
                    </div>
                    <div>
                      <p className="blog-info" onClick={HandleBlog}>
                        Read More..
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="blogs-outer-wrapper">
                  <div className="blogs-inner-wrapper">
                    <div className="blogs-img-date-wrapper">
                      <div className="blogs-img">
                        <img src={IMAGES.bgRequestcallBack} alt="" />
                      </div>
                      <div className="blog-date-wrapper">
                        <MdOutlineCalendarMonth size={30} />
                        <p>May 12, 2023</p>
                      </div>
                    </div>
                    <div className="blogs-para">
                      <h4>What Is Web Design?</h4>
                      <p>
                        Web Design is the process of making websites and mobile
                        applications appealing to the target audience. The web
                        designer needs to be good at ...
                      </p>
                    </div>
                    <div>
                      <p className="blog-info" onClick={HandleBlog}>
                        Read More..
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="blogs-outer-wrapper">
                  <div className="blogs-inner-wrapper">
                    <div className="blogs-img-date-wrapper">
                      <div className="blogs-img">
                        <img src={IMAGES.bgRequestcallBack} alt="" />
                      </div>
                      <div className="blog-date-wrapper">
                        <MdOutlineCalendarMonth size={30} />
                        <p>May 12, 2023</p>
                      </div>
                    </div>
                    <div className="blogs-para">
                      <h4>
                        10 Highly Actionable Content Marketing Tips For More
                        Traffic
                      </h4>
                      <p>
                        What Is Content Marketing? There are many sophisticated
                        definitions of content marketing, but my own simplified
                        version...
                      </p>
                    </div>
                    <div>
                      <p className="blog-info" onClick={HandleBlog}>
                        Read More..
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
