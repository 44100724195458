import React from "react";
import "../../Assets/Styles/GraphicsDesign/ServiceInGd.css";
import { IMAGES } from "../../utils";

const ServicesInGd = () => {
  return (
    <>
      <div className="sectionService">
        <div className="serviceWrapper">
          <div className="container">
            <div className="serviceTitle">
              <h3>Software Used In Graphics Design</h3>
            </div>
            <div className="serviceContent">
              <div className="servicesContentBody">
                <div className="serviceHover-photo">
                  <h5>1. </h5>
                  <h5>ADOBE PHOTOSHOP</h5>
                </div>
                <div className="serviceHover-photo-img">
                  <img src={IMAGES.Ps} />
                </div>
              </div>

              <div className="servicesContentBody">
                <div className="serviceHover-adob">
                  <h5>2.</h5> <h5> ADOBE ILLUSTRATOR</h5>
                </div>
                <div className="serviceHover-adob-img">
                  <img src={IMAGES.Ai} />
                </div>
              </div>

              <div className="servicesContentBody">
                <div className="serviceHover-adobPro">
                  <h5>3. </h5>

                  <h5>ADOBE PREMIERE PRO</h5>
                </div>
                <div className="serviceHover-adobPro-img">
                  <img src={IMAGES.Pr} />
                </div>
              </div>
              <div className="servicesContentBody">
                <div className="serviceHover-coral">
                  <h5>4.</h5>
                  <h5>AFTER EFFECTS</h5>
                </div>
                <div className="serviceHover-coral-img">
                  <img src={IMAGES.Ae} />
                </div>
              </div>
              <div className="servicesContentBody">
                <div className="serviceHover-coral">
                  <h5>5. </h5>
                  <h5>CORAL DRAW</h5>
                </div>
                <div className="serviceHover-coral-img">
                  <img src={IMAGES.Coral} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesInGd;
