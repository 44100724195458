import React from "react";
import "../../Assets/Styles/GraphicsDesign/WhatIsGd.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { IMAGES } from "../../utils";
const WhatIsGd = () => {
  return (
    <>
      <div className="scetionWhatIsDm">
        <div className="container">
          <div className="whatIsDmWrapper">
            <div className="row GdMgview">
              <div className="col-lg-6">
                <div className="whatIsDm">
                  <AnimationOnScroll
                    animateIn="animate__fadeInDown"
                    offset={20}
                  >
                    <h3>What Is Graphics Design?</h3>
                  </AnimationOnScroll>

                  <p>
                    Graphics design is the process of creating visual content
                    that communicates a message to a target audience. It
                    involves the use of typography, images, color, and layout to
                    create designs that are both aesthetically pleasing and
                    effective in conveying a message. Graphics design is used in
                    a wide range of applications, from branding and advertising
                    to product packaging and web design. Some common examples of
                    graphic design include logos, brochures, posters, book
                    covers, and website layouts.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="whatIsGdImgWrapper">
                  <div className="whatIsGdImg-absolute-bg animate__animated animate__pulse animate__infinite	infinite animate__slow	2s"></div>
                  <img src={IMAGES.whatIsGd} className="whatIsGdImg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatIsGd;
