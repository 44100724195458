import React from "react";
import "../../Assets/Styles/GraphicsDesign/CareerPathInGd.css";
import { MdKeyboardArrowRight } from "react-icons/md";

const CareerPathInGd = () => {
  return (
    <>
      <div className="sectionCareerPath">
        <div className="container">
          <div className="careerPathWrapper">
            <div className="row">
              <div className="col-lg-4">
                <div className="eligibility-gd">
                  <h3>Course Eligibility</h3>
                  <ul className="eligibilitylist-gd">
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Business Owners</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Entrepreneurs</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Working Professionals</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Students</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>College Dropouts</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Others</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                {" "}
                <div className="carrerPath">
                  <h3>Career Path </h3>
                  <ul className="eligibilitylist-gd">
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Graphics Artist</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Packaging Designer</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Advertising Art Designer</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Web Designer</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Illustrator</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>User Interface Designer</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Developer For E-Learning</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Layout Designer</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Digital Designer</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                {" "}
                <div className="skillGain">
                  <h3>Skills You  Will Gain</h3>
                  <ul className="eligibilitylist-gd">
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Visual Communication</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Graphics Design</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Branding Communication</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Creativity</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Art History</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Typography</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Design Theory</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Graphics</p>
                    </li>
                    <li>
                      <MdKeyboardArrowRight
                        size={15}
                        className="checkIcon-gd"
                      />
                      <p>Color Theory</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerPathInGd;
