import React, { useEffect } from "react";
import "../Assets/Styles/Testimonials.css";
import { IMAGES } from "../utils";

const Testimonials = () => {
  return (
    <>
      <div className="testimonials">
        <div className="container">
          <div className="test-heading">
            <h3>Testimonials</h3>
            <h5>
              The feedback we've received from clients about working with us.
            </h5>
          </div>

          <div className="testimonial-section">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                      <img src={IMAGES.pujabhole} alt="" />
                      <div className="testimonial-profile-name">
                        <h4>Puja Bhole</h4>
                        <p className="testimonial-company-name">Power BI</p>
                        <p className="testimonial-desc">
                          " I am so grateful to have attended Code Hub
                          Technology Institute. The instructors were
                          knowledgeable and they truly cared about our
                          success.supportive learning environment .I highly
                          recommend Code Hub Technology Institute to any student
                          looking for a top-notch education."
                        </p>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                      <img src={IMAGES.photo} alt="" />
                      <div className="testimonial-profile-name">
                        <h4>Pratik Narkhede</h4>
                        <p className="testimonial-company-name">
                          Web Developer
                        </p>
                        <p className="testimonial-desc">
                          "Code Hub Technology Institute has been a
                          life-changing experience for me. The institute not
                          only provided quality education but also fostered a
                          sense of community among students.Code Hub Technology
                          Institute exceeded my expectations, and I am proud to
                          have been a student there."
                        </p>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                      <img src={IMAGES.photo} alt="" />
                      <div className="testimonial-profile-name">
                        <h4>Swapnil patil</h4>
                        <p className="testimonial-company-name">
                          Software Testing
                        </p>
                        <p className="testimonial-desc">
                          "I can confidently say that Code Hub Technology
                          Institute has prepared me for a successful career. The
                          institute also had strong connections with companies.
                          Code Hub Technology Institute has been instrumental in
                          my professional growth, and I highly recommend it to
                          aspiring students."
                        </p>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                        <img src={IMAGES.Profile} alt="" / />
                        <div className="testimonial-profile-name">
                        <h4>Lorem Ispum</h4>
                        <p className="testimonial-company-name">DThrill Software Solutions LLP</p>
                        <p className="testimonial-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                        <span></span>
                        </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
