import React from "react";
import "../../Assets/Styles/DigitalMarketingCourse.css";
import { IMAGES } from "../../utils";
const DigitalMarketingCourse = () => {
  return (
    <>
      <section>
        <div className="DMCourseListSection">
          <div className="container">
            <div className="DMCourseListTitle">
              <h3>DIGITAL MARKETING COURSES IN PUNE</h3>
            </div>
            <div className="DMCourseListingWrapper">
              <div className="DMCourseListing">
                <div className="DMCourseListing-content">
                  <ul>
                    <li> 50+ Modules In Digital Marketing</li>
                    <li> 50+ Tools In Digital Marketing</li>
                    <li> Industrial Oriented Training</li>
                    <li> Industrial Experienced Faculties</li>
                  </ul>
                </div>
                <div className="courseImgWrapper">
                  <img src={IMAGES.DM8} className="courseImg" />
                </div>{" "}
                <div className="courseRight">
                  <ul>
                    <li> Weekly New Batches For New Joiner</li>
                    <li> Industrial Training With Live Projects</li>
                    <li> Placements & Campus Drives</li>
                    <li> Both Theoretical & Practical Training</li>
                    <li>Most Trusted & Recommended Institute In Training</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DigitalMarketingCourse;
