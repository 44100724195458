import React from "react";
import "../../Assets/Styles/Testimonials.css";
import { IMAGES } from "../../utils";

const TestimonialsInGd = () => {
  return (
    <>
      <div className="testimonials">
        <div className="container">
          <div className="test-heading">
            <h3>Testimonials</h3>
            <h5>
              The feedback we've received from clients about working with us.
            </h5>
          </div>

          <div className="testimonial-section">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                      <img src={IMAGES.pujabhole} alt="" />
                      <div className="testimonial-profile-name">
                        <h4></h4>
                        {/* <p className="testimonial-company-name">
                          DThrill Software Solutions LLP
                        </p> */}
                        <p className="testimonial-desc">
                          "The institute's faculty members were dedicated and
                          knowledgeable, and they encouraged us to push the
                          boundaries of our creativity. The curriculum
                          incorporated both technical skills and design theory,
                          giving us a well-rounded education.I highly recommend
                          Code Hub Technology Institute to anyone looking to
                          pursue a career in graphics design."
                        </p>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                      <img src={IMAGES.photo} alt="" />
                      <div className="testimonial-profile-name">
                        <h4>Kishor Patil</h4>
                        <p className="testimonial-company-name">
                          Graphics Designer
                        </p>
                        <p className="testimonial-desc">
                          "Code Hub Technology institute provided a
                          comprehensive education that covered various design
                          principles and software applications of graphics
                          design. Focus on practical projects.Code Hub
                          Technology also gives best support to build portfolio
                          and helps to secure jobs.I am confident in my graphics
                          designing skills.Most recommended Institute."
                        </p>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                      <img src={IMAGES.photo} alt="" />
                      <div className="testimonial-profile-name">
                        <h4>kishor Patil</h4>
                        <p className="testimonial-company-name">
                        Graphics Designer
                        </p>
                        <p className="testimonial-desc">
                          "Code Hub Technology Institute's graphics design
                          program was a transformative experience for me.Code
                          Hub Technology Institute's emphasis on building a
                          portfolio and networking helped me secure freelance
                          opportunities even before graduation. I'm grateful for
                          the education and support I received at Code Hub
                          Technology Institute."
                        </p>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                        <img src={IMAGES.Profile} alt="" / />
                        <div className="testimonial-profile-name">
                        <h4>Lorem Ispum</h4>
                        <p className="testimonial-company-name">DThrill Software Solutions LLP</p>
                        <p className="testimonial-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                        <span></span>
                        </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialsInGd;
