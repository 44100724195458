import React from "react";
import "../../Assets/Styles/SyllabusInDm.css"
import { RxDash } from "react-icons/rx";

const SyllabusInDm = () => {
  const basic = [
    { title: "Introduction To Online Digital Marketing" },
    { title: "Importance Of Digital Marketing" },
    { title: "Traditional Vs Digital Marketing" },
    { title: "Types of Digital Marketing" },
    { title: "Increasing Visibility" },
    { title: "Visitors Engagement" },
    { title: "Bringing Targeted Traffic" },
    { title: "Lead Generation" },
  ];
  const smm = [
    { title: "What is SMM?" },
    { title: "Creating Facebook Page For Business" },
    { title: "Facebook Analytics" },
    { title: "Facebook Advertising and Its Types In Detail" },
    { title: "Creating Advertising Campaigns" },
    { title: "Introduction To Twitter" },
    { title: "Retweets, Clicks" },
    { title: "Conversions" },
    { title: "Hashtags" },
    { title: "LinkedIn Optimization" },
    { title: "What Is Linkedin?" },
    { title: "Branding On LinkedIn" },
    { title: "Marketing On LinkedIn Groups" },
    { title: "Competeter Analysis" },
    { title: "What is Hashtag? Why use of Hashtag?" },
    { title: "Content Marketing" },
    { title: "Posting" },
    { title: "Facebook Campaign" },
  ];
  const analysis = [
    { title: "What is keyword" },
    { title: "Types Of Keywords" },
    { title: "Tools Used For Keyword Research" },
    { title: "Keyword Research And Analysis" },
    { title: "Localized Keyword Research" },
    { title: "Competitor Website Keyword Analysis" },
    { title: "Choosing Right Keywords To The Project" },
  ];

  const seo = [
    { title: "Introduction To Search Engine Optimization" },
    { title: "Types of SEO" },
    { title: "How Did Search Engine work?" },
    { title: "Google Processing" },
    { title: "Indexing" },
    { title: "Crawling" },
    { title: "Audit" },
  ];
  const gmb = [
    { title: "Introduction of GMB" },
    { title: "Local SEO" },
    { title: "Google Review" },
    { title: "Google ad" },
  ];

  const onp = [
    { title: "Meta Metadata Optimization " },
    { title: "H1, H2, H3 Tags Optimization " },
    { title: "Meta Discription " },
    { title: "Image Optimization" },
    { title: " URL Optimization " },
    { title: "Landing Page Optimization " },
    { title: "Internal Linking" },
    { title: "No-Follow And Do-Follow" },
    { title: "301 Redirection" },
    { title: "Creating XML" },
    { title: "Sitemap" },
    { title: "404 Error Pages" },
    { title: "Robot.txt" },
    { title: "CanonicalImplementation" },
  ];

  const onf = [
    { title: "ink Building Tips & Techniques " },
    { title: "rticle Submission " },
    { title: "Difference Between White Hat And " },
    { title: " Black Hat SEO Image Submission" },
    {
      title:
        "Video Submission Directory Submission Social Bookmarking Submission",
    },
    { title: " Search Engine Submission " },
    { title: " PPT Submission " },
    { title: " PDF Submission " },
    { title: " Web 2.0 Submission" },
  ];

  const cs = [
    { title: "Business Listing" },
    { title: "Blog Commenting " },
    { title: "Citations" },
    { title: " Profile link" },
    { title: "creations Infographics Submission" },
  ];

  const gwa = [
    { title: " Google Analytics" },
    { title: " Content" },
    { title: "Real-Time" },
    { title: " Visitors" },
    { title: " Audience" },
    { title: " Live Data" },
    { title: " Acquisition" },
    { title: "Demographics" },
    { title: " Traffic Sources" },
    { title: "Getting Started With Google Analytics" },
    { title: "Behavior" },
  ];
  const ssc = [
    { title: "Adding site and verification" },
    { title: "Crawls stats and Errors" },
    { title: "Setting Geo-target location" },
    { title: "Sitemaps" },
    { title: "Search queries analysis" },
    { title: "Robots.txt and Links Removal" },
    { title: "Filtering search queries" },
    { title: "HTML Suggestion" },
    { title: "External Links report" },
  ];

 

  return (
    <>
      <section className="dmSyllabussection">
        <div className="container">
          <div className="SyllabusTitle">
            <h3>Syllabus In Digital Marketing</h3>
          </div>
          <div className="dmSyllabus-wrapper">
            <div className="row">
              <div className="col-lg-4">
                <div className="syllabus-first-col">
                  <div className="syllabus-details">
                    <div className="chapterName ">
                      <h5>Basics Of Digital Marketing</h5>
                    </div>
                    <div className="chapterDetails">
                      <ul className="chapterDetails-list">
                        {basic?.map((dm, idx) => (
                          <li className="bullets">
                            <RxDash size={20} className="badgeIcon" />
                            <p>{dm.title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="syllabus-details">
                    <div className="chapterName ">
                      <h5>SOCIAL MEDIA MARKETING (SMM)</h5>
                    </div>
                    <div className="chapterDetails">
                      <ul className="chapterDetails-list">
                        {smm?.map((s, idx) => (
                          <li className="bullets">
                            <RxDash size={20} className="badgeIcon" />
                            <p>{s.title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="syllabus-details">
                    <div className="chapterName ">
                      <h5>ANALYSIS AND KEYWORD RESEARCH</h5>
                    </div>
                    <div className="chapterDetails">
                      <ul className="chapterDetails-list">
                        {analysis?.map((a, idx) => (
                          <li className="bullets">
                            <RxDash size={20} className="badgeIcon" />
                            <p>{a.title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                {" "}
                <div className="syllabus-second-col">
                  <div className="syllabus-details">
                    <div className="chapterName ">
                      <h5> Search Engine Optimization SEO</h5>
                    </div>
                    <div className="chapterDetails">
                      <ul className="chapterDetails-list">
                        {seo?.map((seo, idx) => (
                          <li className="bullets">
                            <RxDash size={20} className="badgeIcon" />
                            <p>{seo.title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="syllabus-details">
                    <div className="chapterName ">
                      <h5>Google My Business</h5>
                    </div>
                    <div className="chapterDetails">
                      <ul className="chapterDetails-list">
                        {gmb?.map((gmb, idx) => (
                          <li className="bullets">
                            <RxDash size={20} className="badgeIcon" />
                            <p>{gmb.title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="syllabus-details">
                    <div className="chapterName ">
                      <h5> ON PAGE OPTIMIZATION</h5>
                    </div>
                    <div className="chapterDetails">
                      <ul className="chapterDetails-list">
                        {onp?.map((onp, idx) => (
                          <li className="bullets">
                            <RxDash size={20} className="badgeIcon" />
                            <p>{onp.title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="syllabus-details">
                    <div className="chapterName ">
                      <h5>OFF PAGE OPTIMIZATION</h5>
                    </div>
                    <div className="chapterDetails">
                      <ul className="chapterDetails-list">
                        {onf?.map((onf, idx) => (
                          <li className="bullets">
                            <RxDash size={20} className="badgeIcon" />
                            <p>{onf.title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                {" "}
                <div className="syllabus-third-col">
                  <div className="syllabus-details">
                    <div className="chapterName ">
                      <h5>Classified Submission </h5>{" "}
                    </div>
                    <div className="chapterDetails">
                      <ul className="chapterDetails-list">
                        {cs?.map((cs, idx) => (
                          <li className="bullets">
                            <RxDash size={20} className="badgeIcon" />
                            <p>{cs.title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="syllabus-details">
                    <div className="chapterName ">
                      <h5>GOOGLE WEB ANALYTICS </h5>
                    </div>
                    <div className="chapterDetails">
                      <ul className="chapterDetails-list">
                        {gwa?.map((gwa, idx) => (
                          <li className="bullets">
                            <RxDash size={20} className="badgeIcon" />
                            <p>{gwa.title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>{" "}
                  <div className="syllabus-details">
                    <div className="chapterName ">
                      <h5>WEBMASTER TOOLS (Google Search Console)</h5>{" "}
                    </div>
                    <div className="chapterDetails">
                      <ul className="chapterDetails-list">
                        {ssc?.map((ssc, idx) => (
                          <li className="bullets">
                            <RxDash size={20} className="badgeIcon" />
                            <p>{ssc.title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SyllabusInDm;
