import React from 'react'
import "../../Assets/Styles/ToolsInDm.css"
import { IMAGES } from "../../utils";
const ToolsInDm = () => {
    const tools = [
        { img: IMAGES.t1 },
        { img: IMAGES.t2 },
        { img: IMAGES.t3 },
        { img: IMAGES.t4 },
        { img: IMAGES.t5 },
        { img: IMAGES.t6 },
        { img: IMAGES.t7 },
        { img: IMAGES.t8 },
        { img: IMAGES.t9 },
        { img: IMAGES.t10 },
        { img: IMAGES.t11 },
        { img: IMAGES.t12 },
        { img: IMAGES.t13 },
        { img: IMAGES.t14 },
        { img: IMAGES.t15 },
        { img: IMAGES.t16 },
        { img: IMAGES.t17 },
        { img: IMAGES.t18 },
      ];
  return (
    <>
     <section className="toolsSection">
        <div className="container">
          <div className='toolsTitle'>
            <h3>Tools In Digital Marketing</h3>
          </div>
          <div className="row">
            {tools.map((c, idx) => (
              <div className="col-lg-2 col-md-3 col-sm-6">
                <div className="toolsWrapper">
                  <img src={c.img} className="tools" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default ToolsInDm