import React from "react";
import "../Assets/Styles/Banner.css";
import { IMAGES } from "../utils";
import { Carousel } from "react-bootstrap";

const Banner = () => {
  return (
    <>
      <div className="banner">
        <Carousel indicators={false} className="relative">
          <Carousel.Item interval={3000}>
            <div className="banner-section1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 banner-wrapper">
                    <div className="banner-width">
                      {/* <p className="banner-p1">The Top Level</p> */}
                      <h1 className="banner-h1">Web Design &</h1>
                      <h1 className="banner-h1">Web Development</h1>
                      <p className="banner-p1">
                        The fastest-growing web design and development company
                        in Pune, India is called Code Hub Technology.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="banner-web-img-wrapper">
                      <img src={IMAGES.HomeWebPage} alt=" " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <div className="banner-section2">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-6 banner-wrapper">
                    <div className="banner-width">
                     
                      <h1 className="banner-h1">Digital</h1>
                      <h1 className="banner-h1">Marketing</h1>
                      <p className="banner-p1">
                        Code Hub Technology can assist you if you want to expand
                        your business.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 col-sm-6">
                    <div className="banner-digital-img-wrapper">
                      <img src={IMAGES.HomeDigitalPage} alt=" " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <div className="banner-section3">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-6 banner-wrapper">
                    <div className="banner-width">
                    
                      <h1 className="banner-h1">Graphics</h1>
                      <h1 className="banner-h1">Designing</h1>
                      <p className="banner-p1">
                        One of the greatest graphic design companies in Pune,
                        India is called Code Hub Technology.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 col-sm-6">
                    <div className="banner-graphics-img-wrapper">
                      <img src={IMAGES.HomeGraphicsPage} alt=" " />
                      <img src={IMAGES.HomeGraphicsBGPage} alt=" " className="HomeGraphicsBGPage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <div className="banner-section4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-6 banner-wrapper">
                    <div className="banner-width">
                     
                      <h1 className="banner-h1">Mobile App</h1>
                      <h1 className="banner-h1">Development</h1>
                      <p className="banner-p1">
                        Code Hub Technology can assist you if you want to expand
                        your business.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 col-sm-6">
                    <div className="banner-digital-img-wrapper">
                      <img src={IMAGES.HomeDigitalPage} alt=" " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default Banner;
