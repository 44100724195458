import React from "react";
import "../../Assets/Styles/ModuleInDm.css";
import { IMAGES } from "../../utils";

const ModuleInDm = () => {
  const modules = [
    {
      module: IMAGES.DigitalMarketingOverview,
      icon: IMAGES.mIcon3,
      moduleDesc: "Digital Marketing  Overview",
    },
    {
      module: IMAGES.WebsiteCreation,
      icon: IMAGES.mIcon27,
      moduleDesc: "Website Creation",
    },
    {
      module: IMAGES.SocialMediaAutomation,
      icon: IMAGES.mIcon23,
      moduleDesc: "Social Media Automation",
    },
    {
      module: IMAGES.GraphicsDesigningUsingCanva,
      icon: IMAGES.mIcon9,
      moduleDesc: "Graphics Designing Using Canva",
    },
    {
      module: IMAGES.InstagramMarketing,
      icon: IMAGES.mIcon10,
      moduleDesc: "Instagram Marketing",
    },
    {
      module: IMAGES.FaceBookMarketing,
      icon: IMAGES.mIcon5,
      moduleDesc: "FaceBook Marketing",
    },
    {
      module: IMAGES.TwitterMarketing,
      icon: IMAGES.mIcon25,
      moduleDesc: "Twitter Marketing",
    },
    {
      module: IMAGES.SocialMediaOptimization,
      icon: IMAGES.mIcon24,
      moduleDesc: "Social Media Optimization",
    },
    {
      module: IMAGES.LinkedinMarketing,
      icon: IMAGES.mIcon12,
      moduleDesc: "Linkedin Marketing",
    },
    {
      module: IMAGES.SMSMarketing,
      icon: IMAGES.mIcon22,
      moduleDesc: "SMS Marketing",
    },
    {
      module: IMAGES.LeadGenerationForBusiness,
      icon: IMAGES.mIcon11,
      moduleDesc: "Lead Generation For Business",
    },
    {
      module: IMAGES.WhatsAppMarketing,
      icon: IMAGES.mIcon28,
      moduleDesc: "WhatsApp Marketing",
    },
    {
      module: IMAGES.FreelancingProjects,
      icon: IMAGES.mIcon6,
      moduleDesc: "Freelancing Projects",
    },
    {
      module: IMAGES.YouTubeSEOAdsenseAnalytics,
      icon: IMAGES.mIcon29,
      moduleDesc: "YouTube SEO, Adsense & Analytics",
    },
    {
      module: IMAGES.GoogleAnalytics,
      icon: IMAGES.mIcon7,
      moduleDesc: "Google Analytics",
    },
    {
      module: IMAGES.OnlineReputationManagement,
      icon: IMAGES.mIcon18,
      moduleDesc: "Online Reputation Management",
    },
    {
      module: IMAGES.AffiliateMarketing,
      icon: IMAGES.mIcon1,
      moduleDesc: "Affiliate Marketing",
    },
    {
      module: IMAGES.MakingMoneyViaGoogleAdsense,
      icon: IMAGES.mIcon14,
      moduleDesc: "Making Money Via Google Adsense",
    },
    {
      module: IMAGES.GoogleSearchConsole,
      icon: IMAGES.mIcon8,
      moduleDesc: "Google Search Console",
    },
    {
      module: IMAGES.LocalSEO,
      icon: IMAGES.mIcon13,
      moduleDesc: "Local SEO",
    },
    {
      module: IMAGES.QuoraMarketing,
      icon: IMAGES.mIcon19,
      moduleDesc: "Quora Marketing",
    },
    {
      module: IMAGES.OffpageStategyPlanningExecution,
      icon: IMAGES.mIcon16,
      moduleDesc: "Offpage Stategy, Planning & Execution",
    },
    {
      module: IMAGES.SearchEngineOptimization,
      icon: IMAGES.mIcon21,
      moduleDesc: "Search Engine Optimization",
    },
    {
      module: IMAGES.ContentMarketing,
      icon: IMAGES.mIcon2,
      moduleDesc: "Content Marketing",
    },
    {
      module: IMAGES.EnrollMarketing,
      icon: IMAGES.mIcon4,
      moduleDesc: "Enroll Marketing ",
    },
    {
      module: IMAGES.RemarketingConversion,
      icon: IMAGES.mIcon20,
      moduleDesc: "Remarketing & Conversion",
    },
    {
      module: IMAGES.VideoYoutubeMarketing,
      icon: IMAGES.mIcon26,
      moduleDesc: "Video/YouTube Marketing",
    },
    {
      module: IMAGES.MobileMarketing,
      icon: IMAGES.mIcon15,
      moduleDesc: "Mobile Marketing",
    },
    {
      module: IMAGES.OnlineDisplayAdvertising,
      icon: IMAGES.mIcon17,
      moduleDesc: "Online Display Advertising",
    },
    {
      module: IMAGES.AppStoreOptimization,
      icon: IMAGES.mIcon30,
      moduleDesc: "App Store Optimization",
    },
    {
      module: IMAGES.MobileAppCreation,
      icon: IMAGES.mIcon41,
      moduleDesc: "Mobile App Creation",
    },
    {
      module: IMAGES.VideoEditingUsingOnlineTool,
      icon: IMAGES.mIcon46,
      moduleDesc: "Video Editing Using Online Tool",
    },
    {
      module: IMAGES.BusinessGrowthStrategy,
      icon: IMAGES.mIcon31,
      moduleDesc: "Business Growth Strategy",
    },
    {
      module: IMAGES.DropShipping,
      icon: IMAGES.mIcon32,
      moduleDesc: "Drop Shipping",
    },
    {
      module: IMAGES.PersonalBrandingUsingDigitalMarketing,
      icon: IMAGES.mIcon42,
      moduleDesc: "Personal Branding Using Digital Marketing",
    },
    {
      module: IMAGES.InfluencerMarketing,
      icon: IMAGES.mIcon36,
      moduleDesc: "Influencer Marketing",
    },
    {
      module: IMAGES.ECommerceDevelopmentMarketing,
      icon: IMAGES.mIcon33,
      moduleDesc: "E-Commerce Development & Marketing",
    },
    {
      module: IMAGES.MediaPlanning,
      icon: IMAGES.mIcon40,
      moduleDesc: "Media Planning",
    },
    {
      module: IMAGES.PublicReaction,
      icon: IMAGES.mIcon43,
      moduleDesc: "Public Reaction",
    },
    {
      module: IMAGES.MasterPlanning,
      icon: IMAGES.mIcon39,
      moduleDesc: "Master Planning",
    },
    {
      module: IMAGES.SalesTraining,
      icon: IMAGES.mIcon44,
      moduleDesc: "Sales Training",
    },
    {
      module: IMAGES.MarketingHacks,
      icon: IMAGES.mIcon38,
      moduleDesc: "Marketing Hacks",
    },
    {
      module: IMAGES.ViralMarketing,
      icon: IMAGES.mIcon47,
      moduleDesc: "Viral Marketing",
    },
    {
      module: IMAGES.GrowthHacking,
      icon: IMAGES.mIcon35,
      moduleDesc: "Growth Hacking",
    },
    {
      module: IMAGES.EntrepreneurshipForBusiness,
      icon: IMAGES.mIcon34,
      moduleDesc: "Entrepreneurship For Business",
    },
    {
      module: IMAGES.StartupPlanningStrategy,
      icon: IMAGES.mIcon45,
      moduleDesc: "Startup Planning & Strategy",
    },
    {
      module: IMAGES.LeadershipFundamentals,
      icon: IMAGES.mIcon37,
      moduleDesc: "Leadership Fundamentals",
    },

    {
      module: IMAGES.SocialMediaMarketing,
      icon: IMAGES.mIcon57,
      moduleDesc: "Social media marketing",
    },
    {
      module: IMAGES.Advertising,
      icon: IMAGES.mIcon48,
      moduleDesc: "Advertising",
    },
    {
      module: IMAGES.DigitalDisplayAdvertising,
      icon: IMAGES.mIcon50,
      moduleDesc: "Digital display advertising",
    },
    {
      module: IMAGES.Business,
      icon: IMAGES.mIcon49,
      moduleDesc: "Business",
    },
    {
      module: IMAGES.WebAnalytics,
      icon: IMAGES.mIcon58,
      moduleDesc: "Web analytics",
    },
    {
      module: IMAGES.Marketingcommunications,
      icon: IMAGES.mIcon53,
      moduleDesc: "Marketing communications",
    },
    {
      module: IMAGES.SearchEnginemarketing,
      icon: IMAGES.mIcon55,
      moduleDesc: "Search engine marketing",
    },
    {
      module: IMAGES.WebDesign,
      icon: IMAGES.mIcon59,
      moduleDesc: "Web design",
    },
    {
      module: IMAGES.MarketingAutomation,
      icon: IMAGES.mIcon54,
      moduleDesc: "Marketing automation",
    },
    {
      module: IMAGES.SearchAdvertising,
      icon: IMAGES.mIcon56,
      moduleDesc: "Search advertising",
    },
    {
      module: IMAGES.Media,
      icon: IMAGES.mIcon37,
      moduleDesc: "Media",
    },
    {
      module: IMAGES.DigitalMedia,
      icon: IMAGES.mIcon51,
      moduleDesc: "Digital media",
    },
    {
      module: IMAGES.GoogleAds,
      icon: IMAGES.mIcon52,
      moduleDesc: "Google Ads",
    },
    {
      module: IMAGES.Writing,
      icon: IMAGES.mIcon60,
      moduleDesc: "Writing",
    },
  ];

  return (
    <>
      <section className="moduleSection">
        {/* <img src={IMAGES.a1} className="moduleImagebg" /> */}
        <div className="container">
          <div className="ModulesTitle">
            <h3>Modules In Digital Marketing</h3>
          </div>
          <div className="row">
            {modules.map((m, idx) => (
              <div className="col-lg-3 col-md-6 col-sm-12 moduleWapper">
                <div className="module">
                  <div className="moduleSubWrapper">
                    <div className="moduleImage">
                      <img src={m.module} alt="module web design" />
                    </div>
                    <div className="moduleIcon">
                      <img src={m.icon} alt="digital mrk" />
                    </div>
                  </div>

                  <div className="moduleDescription">
                    <p>{m.moduleDesc}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ModuleInDm;
