import React, { useState, useEffect } from "react";
// import "../../Assets/Styles/DigitalMarketing.css";
import BestDigitalMarketingCourse from "./BestDigitalMarketingCourse";
import DigitalMarketingCourse from "./DigitalMarketingCourse";
import WhyDigitalMarketing from "./WhyDigitalMarketing";
import WhatIsDM from "./WhatIsDM";
import ScopeInDm from "./ScopeInDm";
import ModuleInDm from "./ModuleInDm";
import SyllabusInDm from "./SyllabusInDm";
import CertificateInDm from "./CertificateInDm";
import EligibilityInDm from "./EligibilityInDm";
import ToolsInDm from "./ToolsInDm";
import FaqInDm from "./FaqInDm";
import RegisterationPopUp from "../../auth/RegistrationPopUp";
import TestimonialsInDm from "./TestimonialsInDm";

const DigitalMarketing = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);
  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      {/* section 1 */}
      <BestDigitalMarketingCourse />
      {/* section 2 */}
      <DigitalMarketingCourse />
      {/* section 3  what */}
      <WhatIsDM />
      {/* section 4  why */}
      <WhyDigitalMarketing />
      {/* section 5 */}
      <ScopeInDm />
      {/* section 6  */}
      <ModuleInDm />
      {/* section 7 •	SYLLABUS IN DIGITAL MARKETING COURSE  */}
      <SyllabusInDm />

      {/* setion 8 eligibility */}
      <EligibilityInDm />

      {/* section 9 certificate */}
      <CertificateInDm />

      {/* section 10 Tool */}
      <ToolsInDm />
      <TestimonialsInDm />
      <FaqInDm />
    </>
  );
};

export default DigitalMarketing;
