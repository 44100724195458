import React from "react";
import "../../Assets/Styles/BestDm.css";
import { IMAGES } from "../../utils";

const BestDigitalMarketingCourse = () => {
  return (
    <>
      <section className="best-dm-section">
        <div className="container">
          <div className="BestDmCrsSection">
            <div className="bestDmCrsSection">
              <div className="row">
                <div className="col-lg-6">
                  <div className="bestDmCrsWrapper">
                    <div>
                      <h3>We Provide Best Digital Marketing Courses In Pune</h3>
                    </div>
                    <p>
                      We make sure you are in the forefront because, as we all
                      know, digital strategies are constantly evolving. Our team
                      of professionals wants to help you succeed and has
                      first-hand expertise in the field.
                      <strong>
                        {" "}
                        <a href="https://codehubtech.com/" target="_blank">
                          Code Hub Technology
                        </a>
                      </strong>{" "}
                      is one of the most reputed digital marketing training
                      institutes in Pune. They offer offline courses in digital
                      marketing, including certification courses in SEO, Google
                      AdWords, social media marketing, email marketing, mobile
                      marketing, digital branding, content marketing, and
                      affiliate marketing. We also offer degrees in graphic and
                      web design, as well as digital marketing
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="bestDmCrsImageWrapper">
                    <img
                      src={IMAGES.DM7}
                      alt="landing digital marketing image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BestDigitalMarketingCourse;
