import React, { useState } from "react";
import "../Assets/Styles/CoursesSection.css";
import { IMAGES } from "../utils";
import { Link } from "react-router-dom";

const CoursesSection = () => {
  const [WebDesignShow, setWebDesignShow] = useState(true);
  const [WebDevShow, setWebDevShow] = useState(false);
  const [DigitalShow, setDigitalShow] = useState(false);
  const [GraphicsShow, setGraphicsShow] = useState(false);
  const [showFullstack, setFullstack] = useState(false);
  const [showPython, setPython] = useState(false);
  const [showPowerBI, setPowerBI] = useState(false);
  const [showBA, setBA] = useState(false);
  const [showSoftwareTesting, setSoftwareTesting] = useState(false);
  const [showAptitude, setAptitude] = useState(false);

  const handleMouseOverWebDesign = () => {
    setWebDesignShow(true);
    setWebDevShow(false);
    setDigitalShow(false);
    setGraphicsShow(false);
    setFullstack(false);
    setPython(false);
    setPowerBI(false);
    setBA(false);
    setSoftwareTesting(false);
    setAptitude(false);
  };

  const handleMouseOverWebDev = () => {
    setWebDevShow(true);
    setWebDesignShow(false);
    setDigitalShow(false);
    setGraphicsShow(false);
    setFullstack(false);
    setPython(false);
    setPowerBI(false);
    setBA(false);
    setSoftwareTesting(false);
    setAptitude(false);
  };

  const handleMouseOverFullStack = () => {
    setFullstack(true);
    setWebDevShow(false);
    setWebDesignShow(false);
    setDigitalShow(false);
    setGraphicsShow(false);
    setPython(false);
    setPowerBI(false);
    setBA(false);
    setSoftwareTesting(false);
    setAptitude(false);
  };

  const handleMouseOverDigital = () => {
    setDigitalShow(true);
    setWebDevShow(false);
    setWebDesignShow(false);
    setGraphicsShow(false);
    setFullstack(false);
    setPython(false);
    setPowerBI(false);
    setBA(false);
    setSoftwareTesting(false);
    setAptitude(false);
  };

  const handleMouseOverGraphics = () => {
    setGraphicsShow(true);
    setWebDevShow(false);
    setWebDesignShow(false);
    setDigitalShow(false);
    setFullstack(false);
    setPython(false);
    setPowerBI(false);
    setBA(false);
    setSoftwareTesting(false);
    setAptitude(false);
  };

  const handleMouseOverPython = () => {
    setPython(true);
    setGraphicsShow(false);
    setWebDevShow(false);
    setWebDesignShow(false);
    setDigitalShow(false);
    setFullstack(false);
    setPowerBI(false);
    setBA(false);
    setSoftwareTesting(false);
    setAptitude(false);
  };

  const handleMouseOverPowerBI = () => {
    setPowerBI(true);
    setGraphicsShow(false);
    setWebDevShow(false);
    setWebDesignShow(false);
    setDigitalShow(false);
    setFullstack(false);
    setPython(false);
    setBA(false);
    setSoftwareTesting(false);
    setAptitude(false);
  };

  const handleMouseOverBusinessAnalyst = () => {
    setBA(true);
    setGraphicsShow(false);
    setWebDevShow(false);
    setWebDesignShow(false);
    setDigitalShow(false);
    setFullstack(false);
    setPython(false);
    setPowerBI(false);
    setSoftwareTesting(false);
    setAptitude(false);
  };

  const handleMouseOverSoftwareTesting = () => {
    setSoftwareTesting(true);
    setGraphicsShow(false);
    setWebDevShow(false);
    setWebDesignShow(false);
    setDigitalShow(false);
    setFullstack(false);
    setPython(false);
    setPowerBI(false);
    setBA(false);
    setAptitude(false);
  };

  const handleMouseOverAptitudeCourses = () => {
    setAptitude(true);
    setGraphicsShow(false);
    setWebDevShow(false);
    setWebDesignShow(false);
    setDigitalShow(false);
    setFullstack(false);
    setPython(false);
    setPowerBI(false);
    setBA(false);
    setSoftwareTesting(false);
  };

  return (
    <>
      <div className="courses-section">
        <div>
          <img src={IMAGES.Shape20} alt="" className="shape-20-1" />
        </div>
        <div>
          <img src={IMAGES.Shape20} alt="" className="shape-20-2" />
        </div>
        <div className="container">
          <div className="courses-wrapper">
            <div className="courses-heading">
              <h3>Our Courses</h3>
              <h5>
                A training program where you gain in-depth knowledge of each
                course module.
              </h5>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="courses-list-section">
                  <div className="courses-list-outer-wrapper">
                    <Link to="/webdesign">
                      <p className="" onMouseOver={handleMouseOverWebDesign}>
                        Web Design
                      </p>
                    </Link>
                    <Link to="">
                      <p onMouseOver={handleMouseOverWebDev}>Web Development</p>
                    </Link>
                    <Link to="#">
                      <p onMouseOver={handleMouseOverFullStack}>
                        Full Stack Development
                      </p>
                    </Link>
                    <Link to="/digitalmarketing">
                      <p onMouseOver={handleMouseOverDigital}>
                        Digital Marketing
                      </p>
                    </Link>
                    <Link to="/graphicsdesigning">
                      <p onMouseOver={handleMouseOverGraphics}>
                        Graphics Design
                      </p>
                    </Link>
                    <Link to="#">
                      <p onMouseOver={handleMouseOverPython}>Python</p>
                    </Link>
                    <Link to="#">
                      <p onMouseOver={handleMouseOverPowerBI}>Power BI</p>
                    </Link>
                    <Link to="#">
                      <p onMouseOver={handleMouseOverBusinessAnalyst}>
                        Business Analyst
                      </p>
                    </Link>
                    <Link to="#">
                      <p onMouseOver={handleMouseOverSoftwareTesting}>
                        Software Testing
                      </p>
                    </Link>
                    <Link to="#">
                      <p onMouseOver={handleMouseOverAptitudeCourses}>
                        Aptitude Courses
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 course-right-section">
                {WebDesignShow && (
                  <div>
                    <div>
                      <h4 className="courses-name">Web Design</h4>
                    </div>
                    <div className="courses-hover-img">
                      <img src={IMAGES.CSWebDesign} alt="" />
                    </div>
                  </div>
                )}
                {WebDevShow && (
                  <div>
                    <div>
                      <h4 className="courses-name">Web Development</h4>
                    </div>
                    <div className="courses-hover-img">
                      <img src={IMAGES.CSWebDev} alt="" />
                    </div>
                  </div>
                )}
                {showFullstack && (
                  <div>
                    <div>
                      <h4 className="courses-name">Full Stack Development</h4>
                    </div>
                    <div className="courses-hover-img">
                      <img src={IMAGES.CSFullStack} alt="" />
                    </div>
                  </div>
                )}
                {DigitalShow && (
                  <div>
                    <div>
                      <h4 className="courses-name">Digital Marketing</h4>
                    </div>
                    <div className="courses-hover-img">
                      <img src={IMAGES.CSDigital} alt="" />
                    </div>
                  </div>
                )}
                {GraphicsShow && (
                  <div>
                    <div>
                      <h4 className="courses-name">Graphics Design</h4>
                    </div>
                    <div className="courses-hover-img">
                      <img src={IMAGES.CSGraphics} alt="" />
                    </div>
                  </div>
                )}
                {showPython && (
                  <div>
                    <div>
                      <h4 className="courses-name">Python</h4>
                    </div>
                    <div className="courses-hover-img">
                      <img src={IMAGES.CSPython} alt="" />
                    </div>
                  </div>
                )}
                {showPowerBI && (
                  <div>
                    <div>
                      <h4 className="courses-name">Power BI</h4>
                    </div>
                    <div className="courses-hover-img">
                      <img src={IMAGES.CSPowerBi} alt="" />
                    </div>
                  </div>
                )}
                {showBA && (
                  <div>
                    <div>
                      <h4 className="courses-name">Business Analyst</h4>
                    </div>
                    <div className="courses-hover-img">
                      <img src={IMAGES.CSBA} alt="" />
                    </div>
                  </div>
                )}
                {showSoftwareTesting && (
                  <div>
                    <div>
                      <h4 className="courses-name">Software Testing</h4>
                    </div>
                    <div className="courses-hover-img">
                      <img src={IMAGES.CSSoftTesting} alt="" />
                    </div>
                  </div>
                )}
                {showAptitude && (
                  <div>
                    <div>
                      <h4 className="courses-name">Aptitude Courses</h4>
                    </div>
                    <div className="courses-hover-img">
                      <img src={IMAGES.CSAptitute} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="courses-section">
        <section className="individual-corporate-banking-area" id="services">
          <div className="corporate-banking">
            <div className="corporate-banking-bg"></div>
            <div className="corporate-banking__inner">
              <div className="sec-title">
                <h2>Banking for Corporate</h2>
                <div className="sub-title">
                  <p>High end banking solutions to large corporate.</p>
                </div>
              </div>
              <div className="container corporate-banking__inner-content">
                <ul>
                  <li>
                    <div className="single-features-of-banking single-features-of-banking--style2">
                      <h3>
                        <a href=" ">Web Design</a>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="single-features-of-banking single-features-of-banking--style2">
                      <h3>
                        <a href=" ">Web Development</a>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="single-features-of-banking single-features-of-banking--style2">
                      <h3>
                        <a href=" ">Full Stack Development</a>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="single-features-of-banking single-features-of-banking--style2">
                      <h3>
                        <a href=" ">Digital Marketing</a>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="single-features-of-banking single-features-of-banking--style2">
                      <h3>
                        <a href=" ">Graphics Designing</a>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="single-features-of-banking single-features-of-banking--style2">
                      <h3>
                        <a href=" ">Python</a>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="single-features-of-banking single-features-of-banking--style2">
                      <h3>
                        <a href=" ">Power bI</a>
                      </h3>
                    </div>
                  </li>

                  <li>
                    <div className="single-features-of-banking single-features-of-banking--style2">
                      <h3>
                        <a href=" ">Business Analyst</a>
                      </h3>
                    </div>
                  </li>

                  <li>
                    <div className="single-features-of-banking single-features-of-banking--style2">
                      <h3>
                        <a href=" ">Software Testing</a>
                      </h3>
                    </div>
                  </li>

                  <li>
                    <div className="single-features-of-banking single-features-of-banking--style2">
                      <h3>
                        <a href=" ">Aptitude Course</a>
                      </h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div> */}
    </>
  );
};

export default CoursesSection;
