import React from "react";
import "../../Assets/Styles/Services/ITCorporateTraining.css";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { useState, useEffect } from "react";
import { IMAGES } from "../../utils";
import RegisterationPopUp from "../../auth/RegistrationPopUp";

const ITTraining = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="it-training">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="it-train-edu-text">
                <h5>#Education Anywhere</h5>
                <h3>
                  Want To Do Career In <strong>Web Designing?</strong> So You
                  Are On The Right Place!
                </h3>
                <p>
                  We are Web Designing Institute as well as Best Web Designing
                  Company in Pune, who will teach to design. We are the top Web
                  Design Course provider in Pune. All of our teachers are very
                  experienced in IT and enjoy sharing their practical expertise
                  with the students. Our web design coaches very well understand
                  the requirements of the fresher and working specialist.
                </p>
                <p>
                  Take online web design courses to build and advance your
                  design skills. Respondent Web Design requires an appreciation
                  of the web content accessing technologies and networks and of
                  consumer usability needs. Register for online courses in order
                  to develop your web design skills.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="it-train-edu-img">
                <img src={IMAGES.ITTrainEdu} />
              </div>
            </div>
          </div>
          <div className="it-main-wrapper">
            <div className="row">
              <div className="trending-category">
                <h3>Trending Category</h3>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="trend-section">
                  <div className="trend-wrapper">
                    <div>
                      <img
                        src={IMAGES.WebDev2}
                        alt=""
                        className="trend-services-img"
                      />
                      <div className="trend-text-btn">
                        <p>Web Development</p>
                        <button className="animated-button1">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          More Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="trend-section">
                  <div className="trend-wrapper">
                    <div>
                      <img
                        src={IMAGES.WebDesign2}
                        alt=""
                        className="trend-services-img"
                      />
                      <div className="trend-text-btn">
                        <p>Web Design</p>
                        <button className="animated-button1">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          More Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="trend-section">
                  <div className="trend-wrapper">
                    <div>
                      <img
                        src={IMAGES.Digital2}
                        alt=""
                        className="trend-services-img"
                      />
                      <div className="trend-text-btn">
                        <p>Digital Marketing</p>
                        <button className="animated-button1">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          More Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="trend-section">
                  <div className="trend-wrapper">
                    <div>
                      <img
                        src={IMAGES.Graphic2}
                        alt=""
                        className="trend-services-img"
                      />
                      <div className="trend-text-btn">
                        <p>Graphics Design</p>
                        <button className="animated-button1">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          More Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="it-main-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="it-coaching-img">
                  <img src={IMAGES.ITTrainCoaching} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="it-train-coaching-text">
                  <h3>
                    We provide exclusive Coaching Courses & Lifebuilding Edu
                  </h3>
                  <p className="it-train-para">
                    Whether you’re looking to start a new career or change your
                    current one, Professional Certificates on Coursera help you
                    become job ready. Learn at your own pace from top companies
                    and universities, apply your new skills to hands-on projects
                    that showcase your expertise to potential employers, and
                    earn a career credential to kickstart your new career.
                  </p>
                  <div className="it-coaching-list">
                    <div className="it-coaching-icon-text">
                      <FaRegArrowAltCircleRight
                        size={20}
                        className="coching-icon-arrow"
                      />
                      <span className="coaching">
                        High Quality Education Video
                      </span>
                    </div>
                    <div className="it-coaching-icon-text">
                      <FaRegArrowAltCircleRight
                        size={20}
                        className="coching-icon-arrow"
                      />
                      <span className="coaching">
                        You can learn anything online
                      </span>
                    </div>
                    <div className="it-coaching-icon-text">
                      <FaRegArrowAltCircleRight
                        size={20}
                        className="coching-icon-arrow"
                      />
                      <span className="coaching">
                        We list your options by state
                      </span>
                    </div>
                  </div>
                  <div className="red-blue-btn">
                    <div className="red-blue-btn-wrapper">
                      <div className="trend-text-blue-btn">
                        <button className="animated-button1">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <p className="start-here-btn">Start Here</p>
                          <FiArrowRight size={18} />
                        </button>
                      </div>
                    </div>
                    <div className="trend-text-red-btn">
                      <div className="red-blue-btn-wrapper">
                        <button className="animated-button1">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>Learn More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="it-main-wrapper">
            <div className="community-text">
              <h3>
                Start Growing With Community & Experience Endless Possibilities
              </h3>
              <h5>
                and get started with a free 1 month trial for your business
              </h5>
              <div className="comm-btn">
                <button className="animated-button1">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <p className="network-btn">Join The Network</p>
                  <FiArrowRight size={18} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="it-main-wrapper">
          <div className="learn-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">
                  <div className="learn-text">
                    <h5>DThrill - Learn Something New</h5>
                    <h3>
                      Learn From Any Location In The World With{" "}
                      <strong>DThrill Edu</strong>
                    </h3>
                    <p>
                      Duis consectetur feugiat auctor. Morbi nec enim luctus,
                      feugiat arcu id, ultricies ante. Duis vel massa eleifend,
                      porta est non.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="row it-count-wrappper">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="learn-count">
                        <h4>12</h4>
                        <p>Certified Teachers</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="learn-count">
                        <h4>345</h4>
                        <p>Complete Training</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ITTraining;
