import React from "react";
import { IMAGES } from "../../utils";
import "../../Assets/Styles/GraphicsDesign/BestGdCrs.css";
import { MdCheckCircle } from "react-icons/md";
import "animate.css";

const BestGdCrs = () => {
  return (
    <>
      <div className="sectionBestGd">
        <div className="container">
          <div className="BestGdWrapper">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="BestGdImage">
                  <img
                    src={IMAGES.bestGd1}
                    alt="Best Gd course in pune"
                    className="GdImg1  animate__animated animate__fadeInDown"
                  />
                  <img
                    src={IMAGES.bestGd2}
                    alt="Best Gd course in pune"
                    className="GdImg2"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="BestGdDescription">
                  <div className="BestGdTitle">
                    <h3>We Provide Best Graphics Design Courses In Pune</h3>
                    <p>
                    <strong>Code Hub Technology</strong> of Design is another
                      reputed design institute in Pune that offers undergraduate
                      and postgraduate courses in graphic design, product
                      design, and more
                    </p>
                  </div>
                  <div className="CourseDescription">
                    <h5>
                      <strong>GRAPHICS DESIGN COURSES IN PUNE</strong>
                    </h5>
                    <div className="row">
                      <div className="col-lg-8 animate__animated animate__fadeInRight">
                        <div className="GdIcon">
                          <MdCheckCircle size={15} className="gdCheckIcon" />
                          <p> 100% Job Guarantee</p>
                        </div>
                        <div className="GdIcon">
                          <MdCheckCircle size={15} className="gdCheckIcon" />
                          <p> Industrial Oriented Training</p>
                        </div>
                        <div className="GdIcon">
                          <MdCheckCircle size={15} className="gdCheckIcon" />
                          <p> Industrial Experienced Faculties</p>
                        </div>
                        <div className="GdIcon">
                          <MdCheckCircle size={15} className="gdCheckIcon" />
                          <p> Weekly Batches For New Joiner</p>
                        </div>
                        <div className="GdIcon">
                          <MdCheckCircle size={15} className="gdCheckIcon" />
                          <p> Most Trusted Institute Training</p>
                        </div>
                      </div>
                      <div className="col-lg-4"></div>
                      <div className="col-lg-2"></div>
                      <div className="col-lg-10 CourseDescription-bottom animate__animated animate__fadeInLeft">
                        <div className="GdIcon">
                          <MdCheckCircle size={15} className="gdCheckIcon" />
                          <p> Industrial Training With pve Projects</p>
                        </div>
                        <div className="GdIcon">
                          <MdCheckCircle size={15} className="gdCheckIcon" />
                          <p> Placements & Campus Drives</p>
                        </div>
                        <div className="GdIcon">
                          <MdCheckCircle size={15} className="gdCheckIcon" />
                          <p> Most Recommended Institute Training</p>
                        </div>
                        <div className="GdIcon">
                          <MdCheckCircle size={15} className="gdCheckIcon" />
                          <p> Both Theoretical & Practical Training</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BestGdCrs;
