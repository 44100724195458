import React from "react";
import "../../Assets/Styles/WhatIsDm.css";
import { IMAGES } from "../../utils";
const WhatIsDM = () => {
  return (
    <>
      <section className="whatIsDmSection">
        <div className="container">
          <div className="whatIsDmWrapper">
            <div className="row">
              <div className="col-lg-6">
                <div className="whatIsDmImage">
                  <img src={IMAGES.whatIsDm} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="whatIsDmText">
                  <div className="whatIsDmHeading">
                    <h3>What is Digital Marketing ?</h3>
                  </div>
                  <p>
                    Digital marketing is the use of digital channels and
                    technologies to promote a product, service, or brand. It
                    encompasses a range of tactics and techniques to reach
                    customers and prospects online and drive engagement,
                    conversions, and sales. So enroll in the best digital
                    marketing courses in Pune at <strong>
                        {" "}
                        <a href="https://codehubtech.com/" target="_blank">
                          Code Hub Technology
                        </a>
                      </strong>{" "} institute.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatIsDM;
