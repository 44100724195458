import React from "react";
import "../../Assets/Styles/Testimonials.css";
import { IMAGES } from "../../utils";

const TestimonialsInDm = () => {
  return (
    <>
      <div className="testimonials">
        <div className="container">
          <div className="test-heading">
            <h3>Testimonials</h3>
            <h5>
              The feedback we've received from clients about working with us.
            </h5>
          </div>

          <div className="testimonial-section">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                      <img src={IMAGES.photo} alt="" />
                      <div className="testimonial-profile-name">
                        <h4>Puja Chopade</h4>
                        <p className="testimonial-company-name">
                          Digital Marketing
                        </p>
                        <p className="testimonial-desc">
                          "Enrolling in the digital marketing program at Code
                          Hub Technology Institute was a game-changer for me.The
                          curriculum covered all aspects of digital
                          marketing.The institute provided hands-on training
                          gives practical skills.The supportive learning
                          environment. Highly recommend..!!Code Hub Technology
                          Institute."
                        </p>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                      <img src={IMAGES.photo} alt="" />
                      <div className="testimonial-profile-name">
                        <h4>Vrushali Chaudhari</h4>
                        <p className="testimonial-company-name">
                          Digital Marketing
                        </p>
                        <p className="testimonial-desc">
                          "Code Hub Technology Institute's digital marketing
                          program exceeded my expectations. The instructors were
                          engaging and passionate, and they brought real-world
                          examples to the classroom.I am grateful for the
                          education I received and would recommend Code Hub
                          Technology Institute to aspiring digital marketers."
                        </p>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="testimonial-outer-wrapper">
                  <div className="testimonial-inner-wrapper">
                    <div className="testimonial-profile">
                      <img src={IMAGES.photo} alt="" />
                      <div className="testimonial-profile-name">
                        <h4>Pragati Khachane</h4>
                        <p className="testimonial-company-name">
                          Digital Marketing
                        </p>
                        <p className="testimonial-desc">
                          "Code Hub Technology Institute's digital marketing
                          program was a fantastic experience.The institute also
                          offered opportunities for internships.Code Hub
                          Technology Institute's career services team provided
                          guidance. I am confident in my digital marketing
                          skills, thanks to the education I received at Code Hub
                          Technology Institute."
                        </p>

                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialsInDm;
