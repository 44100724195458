import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./layouts/Header";
import AboutUs from "./Pages/AboutUs";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import Courses from "./Pages/Courses";
import Placements from "./Pages/Placements";
import DigitalMarketing from "./Pages/DigitalMarketing/DigitalMarketing";
import GraphicsDesign from "./Pages/GraphicsDesign/GraphicsDesign";
import ContactUs from "./Pages/ContactUs";
import Footer from "./layouts/Footer";
import DigitalMarketing_service from "./Pages/Services/DigitalMarketing_service";
import GraphicsDesign_service from "./Pages/Services/GraphicDesign_service";
import WebDesign_service from "./Pages/Services/WebDesign_service";
import WebDevelopment_service from "./Pages/Services/WebDevelopment_service";
import WebDesign from "./Pages/WebDesign";
import ITCorporateTraining from "./Pages/Services/ITCorporateTraining";
import { useEffect, useState } from "react";
import Services from "./Pages/Services";
import { IMAGES } from "./utils";
import { MdClose } from "react-icons/md";
import { iucontact } from "./config/api";
import MobileAppDevelopment from "./Pages/Services/MobileAppDevelopment";
import ViewBlog from "./Pages/Blogs/ViewBlog";
import WebDevelopment from "./Pages/WebDevelopment";
import FullStackDev from "./Pages/FullStackDev";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [state, setState] = useState({
    ContactID: 0,
    FirstName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    Message: "",
    CreatedBy: 1,
    errors: [],
  });
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });
  };
  const handleOffCanvasClose = () => {
    clearState();
  };
  const clearState = () => {
    setState({
      ContactID: 0,
      FirstName: "",
      LastName: "",
      EmailID: "",
      Mobile: "",
      Message: "",
      CreatedBy: 1,
      errors: "",
    });
  };
  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!state.FirstName) {
      IsValid = false;
      errors["FirstName"] = "Full Name Is Required";
    } else if (state.FirstName.length < 2) {
      IsValid = false;
      errors["FirstName"] = "Must Be 15 Characters ";
    } else if (!state.FirstName.match(regex_name)) {
      IsValid = false;
      errors["FirstName"] = "Please Enter Valid Name";
    } else {
      errors["FirstName"] = "";
    }

    if (!state.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile number Is Required";
    } else if (state.Mobile.length < 10 || state.Mobile.length > 10) {
      IsValid = false;
      errors["Mobile"] = "Mobile Number Must Have 10 Digits";
    } else if (!state.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }

    if (!state.EmailID) {
      IsValid = false;
      errors["EmailID"] = "Email ID Is Required";
    } else if (!state.EmailID.match(regex_email)) {
      IsValid = false;
      errors["EmailID"] = "Please Enter valid Email id";
    } else {
      errors["EmailID"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = async () => {
    console.log("submit-->", state);
    if (validate()) {
      await iucontact(state).then((res) => {
        console.log("contact--->", res[0]);
        if (res.length == 1) {
          alert("You are Successfully Registed with us...!!");
          clearState();
        }
      });
    } else {
      alert("Please Enter Proper Details..!");
    }
  };

  return (
    <>
      <Header />
      <div className="sticky-reg-btn-wrapper">
        <button
          className="sticky-reg-btn "
          data-bs-toggle="offcanvas"
          href="#offcanvasExample"
          role="button"
          aria-controls="offcanvasExample"
        >
          Register
        </button>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/placements" element={<Placements />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/digitalmarketing" element={<DigitalMarketing />} />
        <Route path="/graphicsdesigning" element={<GraphicsDesign />} />
        <Route path="/fullstackdev" element={<FullStackDev />} />
        <Route path="/webdesign" element={<WebDesign />} />
        <Route path="/webdevelopment" element={<WebDevelopment />} />
        <Route path="/ViewBlog" element={<ViewBlog />} />


        {/* service */}
        <Route
          path="/digitalmarketingservice"
          element={<DigitalMarketing_service />}
        />
        <Route
          path="/graphicsdesigningservice"
          element={<GraphicsDesign_service />}
        />
        <Route path="/webdesignservice" element={<WebDesign_service />} />
        <Route
          path="/webdevelopemnetservice"
          element={<WebDevelopment_service />}
        />
        <Route path="/itcorporatetraining" element={<ITCorporateTraining />} />
        <Route path="/mobileApp" element={<MobileAppDevelopment />} />
      </Routes>
      <Footer />
      {/* <RegistrationOffcanvas show={show}  data={toggle} /> */}

      <div
        className="offcanvas offcanvas-start sticky-reg-modal relative"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header sticky-reg-modal-header">
          <button
            type="button"
            className="sticky-close-btn-wrapper"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={handleOffCanvasClose}
          >
            <MdClose size={25} className="sticky-close-btn-icon" />
          </button>
        </div>
        <div className="offcanvas-body sticky-reg-modal-body">
          <div className="row">
            <div className="col-lg-7">
              <div className="sticky-reg-modal-content">
                <h3>Register</h3>
                <p className="sticky-reg-text">
                  Utilize this form to register for your Course.
                </p>
                <div className="w-100 mb-3 px-2">
                  <input
                    type="text"
                    className="reg-form-control"
                    placeholder="Full Name"
                    autoComplete="off"
                    name="FirstName"
                    value={state.FirstName}
                    onChange={handleChange}
                  />
                  {state.errors ? (
                    <p className="reg-form-errors">{state.errors.FirstName}</p>
                  ) : null}
                </div>
                <div className="w-100 mb-3 px-2">
                  <input
                    type="text"
                    className="reg-form-control"
                    placeholder="Mobile"
                    autoComplete="off"
                    name="Mobile"
                    value={state.Mobile}
                    onChange={handleChange}
                  />
                  {state.errors ? (
                    <p className="reg-form-errors">{state.errors.Mobile}</p>
                  ) : null}
                </div>
                <div className="w-100 mb-3 px-2">
                  <input
                    type="text"
                    className="reg-form-control"
                    placeholder="Email"
                    autoComplete="off"
                    name="EmailID"
                    value={state.EmailID}
                    onChange={handleChange}
                  />
                  {state.errors ? (
                    <p className="reg-form-errors">{state.errors.EmailID}</p>
                  ) : null}
                </div>
                <div className="w-100 mb-3 px-2">
                  <textarea
                    className="reg-form-control"
                    rows={2}
                    autoComplete="off"
                    name="Message"
                    value={state.Message}
                    onChange={handleChange}
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="w-100 px-2 sticky-reg-submit-btn-wrapper">
                  <button
                    className="sticky-reg-submit-btn"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="sticky-reg-img-wrapper">
                <img src={IMAGES.StickyRegister} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
