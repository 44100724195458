import React, { useState, useEffect } from "react";
import "../Assets/Styles/Services.css";
import { IMAGES } from "../utils";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import RegisterationPopUp from "../auth/RegistrationPopUp";

const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="separate-services-section">
        <div className="separate-services-section-wrapper">
          <div className="separate-services-heading">
            <h3>Services</h3>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="separate-service-outer-wrapper">
                  <div
                    className="separate-services-wrapper"
                    onClick={() => navigate("/webdesignservice")}
                  >
                    <img
                      src={IMAGES.ServicesWebDesign}
                      alt=""
                      className="separate-services-img"
                    />
                    <div className="separate-service-inner-text-wrapper">
                      <h3>Web Design</h3>
                      <p>
                        Our website design showcase illustrates our versatility
                        and commitment. We create affordable tailor-made design
                        solutions for businesses in a diverse range of
                        industries.
                      </p>
                      <div className="services-konw-more">
                        <button onClick={() => navigate("/webdesignservice")}>
                          Know More{" "}
                          <FiArrowRight size={18} className="rightIcon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="separate-service-outer-wrapper">
                  <div
                    className="separate-services-wrapper"
                    onClick={() => navigate("/webdevelopemnetservice")}
                  >
                    <img
                      src={IMAGES.WebsiteDev}
                      alt=""
                      className="separate-services-img"
                    />
                    <div className="separate-service-inner-text-wrapper">
                      <h3>Web Development</h3>
                      <p>
                        It’s important to have your site performing well, and
                        also be accessible to users 24/7 (without technical
                        difficulties).
                      </p>
                      <div className="services-konw-more">
                        <button
                          onClick={() => navigate("/webdevelopemnetservice")}
                        >
                          Know More{" "}
                          <FiArrowRight size={18} className="rightIcon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="separate-service-outer-wrapper">
                  <div
                    className="separate-services-wrapper"
                    onClick={() => navigate("/digitalmarketingservice")}
                  >
                    <img
                      src={IMAGES.ServicesDM}
                      alt=""
                      className="separate-services-img"
                    />
                    <div className="separate-service-inner-text-wrapper">
                      <h3>Digital Marketing</h3>
                      <p>
                        The most relavant websites for users search query should
                        be the top ranking search. You should focus on the
                        strategy on attracting people who will engage and
                        convert.
                      </p>
                      <div className="services-konw-more">
                        <button
                          onClick={() => navigate("/digitalmarketingservice")}
                        >
                          Know More{" "}
                          <FiArrowRight size={18} className="rightIcon" />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="separate-service-outer-wrapper">
                  <div
                    className="separate-services-wrapper"
                    onClick={() => navigate("/graphicsdesigningservice")}
                  >
                    <img
                      src={IMAGES.ServicesGD}
                      alt=""
                      className="separate-services-img"
                    />
                    <div className="separate-service-inner-text-wrapper">
                      <h3>Graphics Design</h3>
                      <p>
                        Companies use graphic design service to promote and sell
                        products as well as to make services more pleasing
                        manner.
                      </p>
                      <div className="services-konw-more">
                        <button
                          onClick={() => navigate("/graphicsdesigningservice")}
                        >
                          Know More{" "}
                          <FiArrowRight size={18} className="rightIcon" />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="separate-service-outer-wrapper">
                  <div className="separate-services-wrapper">
                    <img
                      src={IMAGES.MobileAppCardimg}
                      alt=""
                      className="separate-services-img"
                    />
                    <div className="separate-service-inner-text-wrapper">
                      <h3>Mobile App Development</h3>
                      <p>
                        We assist our clients in establishing their brands and
                        attracting a larger audience by developing mobile
                        applications.
                      </p>
                      <div className="services-konw-more">
                        <button onClick={() => navigate("/mobileApp")}>
                          Know More
                          <FiArrowRight size={18} className="rightIcon" />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="separate-service-outer-wrapper">
                  <div className="separate-services-wrapper">
                    <img
                      src={IMAGES.ServicesITTraining}
                      alt=""
                      className="separate-services-img"
                    />
                    <div className="separate-service-inner-text-wrapper">
                      <h3>IT Corporate Training</h3>
                      <p>
                        Respondent Web Design requires an appreciation of the
                        web content accessing technologies and networks and of
                        consumer usability needs.
                      </p>
                      <div className="services-konw-more">
                        <button
                          onClick={() => navigate("/itcorporatetraining")}
                        >
                          Know More
                          <FiArrowRight size={18} className="rightIcon" />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
