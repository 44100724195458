import React from "react";
import "../Assets/Styles/WhoCanDoThisCourse.css";
import { TbArrowBigRight } from "react-icons/tb";

const WhoCanDoThisCourse = () => {
  const course = [
    { course: "Freshers" },
    { course: "Experience" },
    { course: "Any Graduate" },
    { course: "BE / BSc / BTech Candidates" },
    { course: "Any Post-Graduate" },
    { course: "MCA / MSC  Candidates" },
    { course: "Working Professionals" },
  ];

  return (
    <>
      <div className="who-can-do-this-course-section">
        <div className="who-can-do-this-course">
          <div className="course-heading">
            <h3>Eligibility</h3>
            <h5>
              “You don't have to be great to start, but you have to start to be
              great.”
            </h5>
          </div>
          <div className="course-section">
            <div className="container">
              <div className="row who-can-join">
                {course?.map((c, idx) => (
                  <div className="col-lg-6 col-md-12 col-sm-12" key={idx}>
                    <div className="who-can-do-this-course-list">
                      <div>
                        <TbArrowBigRight size={25} className="course-icon" />
                      </div>
                      <div>
                        <p>{c.course}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoCanDoThisCourse;
