import React from "react";
import "../Assets/Styles/FullStackDev.css";
import { FaAsterisk } from "react-icons/fa";
import { AnimationOnScroll } from "react-animation-on-scroll";

const FullStackDev = () => {
  const syllabus = [
    { txt: "HTML" },
    { txt: "CSS" },
    { txt: "Bootstrap" },
    { txt: "JavaScript" },
    { txt: "ReactJs" },
    { txt: "NodeJS" },
    { txt: "Database (MySQL / MSSQL)" },
    { txt: "Server" },
    { txt: "Git" },
  ];

  const learn = [
    {
      count: "01",
      heading: "Comprehensive Skill Set",
      para: "Full Stack Developers have a broad range of skills, including proficiency in multiple programming languages, frameworks, databases, and tools. This versatility allows them to handle different parts of the development process and take on diverse projects",
    },

    {
      count: "02",
      heading: "Better Collaboration",
      para: "Understanding both frontend and backend development allows Full Stack Developers to collaborate more effectively with team members who specialize in specific areas. They can communicate and coordinate with designers, frontend developers, backend developers, and other stakeholders more efficiently, leading to smoother project workflows and faster iterations.",
    },
    {
      count: "03",
      heading: "Career Flexibility",
      para: "Full Stack Development skills are in high demand in the job market. By learning Full Stack Web Development, you increase your employability and open up a wider range of career opportunities. You can work as a freelancer, join startups, contribute to large-scale projects, or even pursue entrepreneurship by developing your own web applications.",
    },
    {
      count: "04",
      heading: "End-to-End Development",
      para: "Full Stack Developers can independently handle all aspects of web development, from designing the user interface and implementing frontend functionality to building the server-side logic and managing databases. This self-sufficiency is particularly valuable for startups, small teams, or personal projects where resources and specialized roles may be limited.",
    },
    {
      count: "05",
      heading: "Problem Solving & Troubleshooting",
      para: "Full Stack Developers have a deeper understanding of how different components of a web application interact with each other. This knowledge enables them to identify and resolve issues more effectively, whether they occur in the frontend, backend, or the interaction between the two. They can also optimize performance and enhance user experience by addressing bottlenecks in the system",
    },

    {
      count: "06",
      heading: "Continuous Learning",
      para: "Web Development technologies evolve rapidly, and being a Full Stack Developer requires staying up to date with the latest trends and tools. This continuous learning aspect keeps you engaged and helps you adapt to new technologies and frameworks, ensuring your skills remain relevant in the dynamic software industry.",
    },
  ];

  return (
    <>
      <div className="full-stack-section">
        {/* What is Full Stack Development */}
        <div className="what-full-stack-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="what-full-stack-box-section">
                  <div className="what-full-stack-inner-box">
                    <h3>What is Full Stack ?</h3>
                    <p>
                      Full Stack Web Development refers to the development of
                      both the Client-Side (Frontend) and Server-Side (Backend)
                      components of a web application. It involves working on
                      the User Interface, User Experience, Server Logic, and
                      Database interactions to create a complete and functional
                      Web product.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Importance of learning Full stack development */}
        <div className="imp-learn-section">
          <div className="container">
            <div className="full-stack-imp-heading">
              <h3>Importance of learning Full stack development</h3>
            </div>

            <div className="row">
              {" "}
              {learn.map((l, idx) => (
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="imp-learn-wrapper">
                    <div className="imp-learn-heading">
                      <AnimationOnScroll animateIn="animate__swing">
                        <p>{l.count}</p>
                      </AnimationOnScroll>
                      <AnimationOnScroll animateIn="animate__zoomIn">
                        <h4>{l.heading}</h4>
                      </AnimationOnScroll>
                    </div>
                    <div className="imp-learn-para">
                      <p>{l.para}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* What You will gain after learning this course? */}
        <div className="after-learning-section">
          <div className="container">
            <div className="after-learning-section-heading">
              <h3>What You will gain after learning this course?</h3>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="after-learning-wrapper">
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <h1 className="no-h3">1</h1>
                  </AnimationOnScroll>
                  <div className="after-learning-text">
                    <p>Construct algorithm and structures for problem.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="after-learning-wrapper2">
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <h1 className="no-h3">2</h1>
                  </AnimationOnScroll>
                  <div className="after-learning-text">
                    <p>Learn advance computer fundamentals.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="after-learning-wrapper">
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <h1 className="no-h3">3</h1>
                  </AnimationOnScroll>
                  <div className="after-learning-text">
                    <p>
                      Build Full stack apps using technology like rest api’ s,
                      nodesjs, reactjs and databses like mysql.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Syllabus */}
        <div className="web-dev-syllabus">
          <div className="container">
            <hr className="webdev-hr" />
            <div className="web-dev-heading">
              <h3>Syllabus</h3>
              <p>This Syllabus Covers</p>
            </div>

            <div className="syllabus-wrapper">
              <ul>
                {syllabus?.map((s, idx) => (
                  <li>
                    <FaAsterisk size={20} className="web-dev-syllabus-icon" />
                    {s.txt}
                  </li>
                ))}
              </ul>
            </div>

            <hr className="webdev-hr" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FullStackDev;
