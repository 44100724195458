import React, { useState, useEffect } from "react";
import BestGdCrs from "./BestGdCrs";
import WhatIsGd from "./WhatIsGd";
import WhyGd from "./WhyGd";
import ServicesInGd from "./ServicesInGd";
import CareerPathInGd from "./CareerPathInGd";
import FaqinGd from "./FaqinGd";
import OurWorksInGd from "./OurWorksInGd";
import RegisterationPopUp from "../../auth/RegistrationPopUp";
import TestimonialsInGd from "./TestimonialsInGd";

const GraphicsDesign = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);
  return (
    <>
      {" "}
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <BestGdCrs />
      <WhatIsGd />
      <WhyGd />
      {/* <OurWorksInGd /> */}
      <ServicesInGd />
      <CareerPathInGd />
      <TestimonialsInGd />
      <FaqinGd />
    </>
  );
};

export default GraphicsDesign;
