import React from "react";
import "../Assets/Styles/Footer.css";
// import "../Assets/Styles/SocialIcons.scss";
import { IMAGES } from "../utils";
import { RiInstagramLine, RiTwitterLine } from "react-icons/ri";
import { GrFacebookOption } from "react-icons/gr";
import { TbBrandLinkedin, TbBrandPinterest } from "react-icons/tb";
import { RiMapPinRangeLine } from "react-icons/ri";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsWhatsapp } from "react-icons/bs";
import { RiYoutubeLine } from "react-icons/ri";

const Footer = () => {
  return (
    <>
      <div className="footer-height">
        <footer>
          {/* <img
            src={IMAGES.RoundAbstract}
            alt=""
            className="round-shape-right"
          />
          <img src={IMAGES.RoundAbstract} alt="" className="round-shape-left" />
          <img
            src={IMAGES.RoundAbstract}
            alt=""
            className="round-shape-bottom"
          /> */}
          <div className="footer-top-section">
            <div className="footer-logo-wrapper">
              <img src={IMAGES.WhiteLogo} alt="" className="footer-logo" />
            </div>

            <ul className="social-icon-wrapper">
              <li>
                <a
                  href="https://www.facebook.com/CodehubTechnology/"
                  target="_about"
                  className="social"
                >
                  {/* <GrFacebookOption size={35} className="social-icon" /> */}
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/codehubtechnology/"
                  target="_about"
                  className="social"
                >
                  {/* <RiInstagramLine size={35} className="social-icon" /> */}
                </a>
              </li>
              {/* <a href="##" className="Namesocial google">
                <RiTwitterLine size={35} className="social-icon" />
              </a> */}
              <li>
                <a
                  href="https://www.linkedin.com/company/94821700/admin/"
                  target="_about"
                  className="social"
                >
                  {/* <TbBrandLinkedin size={35} className="social-icon" /> */}
                </a>
              </li>
              <li>
                <a href="https://in.pinterest.com/codehubt/" className="social">
                  {/* <TbBrandPinterest size={35} className="social-icon" /> */}
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCcwAxB17gR235PNZX_A-7SQ"
                  className="social"
                >
                  {/* <RiYoutubeLine size={35} className="social-icon" /> */}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-content-wrappper">
            <div className="row">
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="footer-outer-wrapper">
                  <div className="footer-links-wrapper">
                    <div className="footer-wrapper">
                      <h6>Quick Links</h6>
                      <ul>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="/webdesignservice">Home</a>
                        </li>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="/courses"> Courses</a>
                        </li>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="/placements"> Placements</a>
                        </li>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="/services">Services</a>
                        </li>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="/aboutus">About Us</a>
                        </li>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="/contactus">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="footer-outer-wrapper">
                  <div className="footer-services-wrapper">
                    <div className="footer-wrapper">
                      <h6>Services</h6>
                      <ul>
                        <li>
                          <div>
                            <MdKeyboardArrowRight size={20} />
                          </div>
                          <div>
                            <a href="/webdesignservice"> Web Design</a>
                          </div>
                        </li>
                        <li>
                          <div>
                            <MdKeyboardArrowRight size={20} />
                          </div>
                          <div>
                            <a href="/webdevelopemnetservice">
                              Web Development
                            </a>
                          </div>
                        </li>
                        <li>
                          <div>
                            <MdKeyboardArrowRight size={20} />
                          </div>
                          <div>
                            <a href="/digitalmarketingservice">
                              Digital Marketing
                            </a>
                          </div>
                        </li>
                        <li>
                          <div>
                            <MdKeyboardArrowRight size={20} />
                          </div>
                          <div>
                            <a href="/graphicsdesigningservice">
                              Graphics Design
                            </a>
                          </div>
                        </li>
                        <li>
                          <div>
                            <MdKeyboardArrowRight size={20} />
                          </div>
                          <div>
                            <a href=""> Mobile App Development</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12">
                <div className="footer-outer-wrapper">
                  <div className="footer-courses-wrapper">
                    <div className="footer-wrapper">
                      <h6>Courses</h6>
                      <ul>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="/webdesign"> Web Design</a>
                        </li>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="">Web Development</a>
                        </li>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="/digitalmarketing">Digital Marketing</a>
                        </li>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="/graphicsdesigning">Graphics Design</a>
                        </li>
                        <li>
                          <MdKeyboardArrowRight size={20} />
                          <a href="">Mobile App Development</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <div className="footer-outer-wrapper">
                  <div className="footer-info-wrapper">
                    <div className="footer-wrapper">
                      <h6>Informative</h6>
                      <ul>
                        <li>
                          <div>
                            <MdKeyboardArrowRight size={20} />
                          </div>
                          <div>
                            <a href="">FAQ</a>
                          </div>
                        </li>
                        <li>
                          <div>
                            <MdKeyboardArrowRight size={20} />
                          </div>
                          <div>
                            <a href="">Privacy Policy</a>
                          </div>
                        </li>
                        <li>
                          <div>
                            <MdKeyboardArrowRight size={20} />
                          </div>
                          <div>
                            <a href="">Terms and Condition</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="footer-outer-wrapper">
                  <div className="footer-office-wrapper">
                    <div className="footer-office-inner-wrapper">
                      <h6>Head Office</h6>
                      <ul>
                        <li>
                          <div>
                            <RiMapPinRangeLine size={20} />
                          </div>
                          <div className="footer-office-text">
                            <p>
                              Code Hub Technology
                              <br /> Office No. 203, Above Chaitanya Jewellers,
                              Katepuram Chowk, Navi Sangvi, Pimple Gurav, <br />
                              Pune, MH - 411061
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <FaMobileAlt size={20} />
                          </div>
                          <div className="footer-office-text">
                            +91-8668 747 836
                          </div>
                        </li>
                        <li>
                          <div>
                            <HiOutlineMail size={20} />
                          </div>
                          <div className="footer-office-text">
                            info@codehubtech.com
                          </div>
                        </li>
                        <li>
                          <div>
                            <HiOutlineMail size={20} />
                          </div>
                          <div className="footer-office-text">
                            career@codehubtech.com
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-wrapper">
            <p>
              ©Copyright {new Date().getFullYear()} Code Hub Technology. All
              Rights Reserved.
            </p>
          </div>
        </footer>

        {/* <section className="bubble-holder">
          <div className="bubble-1 bubble-container bubble-animation-x">
            <div className="bubble-large bubble-animation-y"></div>
          </div>

          <div className="bubble-2 bubble-container bubble-animation-x">
            <div className="bubble-large bubble-animation-y"></div>
          </div>

          <div className="bubble-3 bubble-container bubble-animation-x">
            <div className="bubble-large bubble-animation-y"></div>
          </div>

          <div className="bubble-4 bubble-container bubble-animation-x">
            <div className="bubble-small bubble-animation-y"></div>
          </div>

          <div className="bubble-5 bubble-container bubble-animation-x">
            <div className="bubble-small bubble-animation-y"></div>
          </div>

          <div className="bubble-6 bubble-container bubble-animation-x">
            <div className="bubble-small bubble-animation-y"></div>
          </div>

          <div className="bubble-7 bubble-container bubble-animation-x">
            <div className="bubble-small bubble-animation-y"></div>
          </div>

          <div className="bubble-8 bubble-container bubble-animation-x">
            <div className="bubble-small bubble-animation-y"></div>
          </div>

          <div className="bubble-9 bubble-static"></div>
          <div className="bubble-10 bubble-static"></div>
        </section> */}
      </div>
      <a href="https://wa.link/q7jivv" target="_about">
        <BsWhatsapp className="wp-float-btn" size={30} />
      </a>
    </>
  );
};

export default Footer;
