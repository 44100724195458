import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../Assets/Styles/RegistrationPopUp.css";
import { MdClose } from "react-icons/md";

const RegisterationPopUp = ({ show, setShow, handleClose }) => {
  // function handleSubmit(e) {
  //   setShow(false);
  // }
  const [state, setState] = useState({
    ContactID: 0,
    FullName: "",
    Email: "",
    Mobile: "",
    Subject: "",
    CreatedBy: 1,
    errors: [],
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });
  };
  const clearStates = () => {
    setState({
      ContactID: 0,
      FullName: "",
      Email: "",
      Mobile: "",
      Subject: "",
      CreatedBy: 1,
      errors: "",
    });
  };
  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!state.FullName) {
      IsValid = false;
      errors["FullName"] = "Full Name Is Required";
    } else if (state.FullName.length < 2) {
      IsValid = false;
      errors["FullName"] = "Must Be 15 Characters ";
    } else if (!state.FullName.match(regex_name)) {
      IsValid = false;
      errors["FullName"] = "Please Enter Valid Name";
    } else {
      errors["FullName"] = "";
    }

    if (!state.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile number Is Required";
    } else if (state.Mobile.length < 10 || state.Mobile.length > 10) {
      IsValid = false;
      errors["Mobile"] = "Mobile Number Must Have 10 Digits";
    } else if (!state.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }

    if (!state.Email) {
      IsValid = false;
      errors["Email"] = "Email ID Is Required";
    } else if (!state.Email.match(regex_email)) {
      IsValid = false;
      errors["Email"] = "Please Enter valid Email id";
    } else {
      errors["Email"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };
  const handleSubmit = () => {
    if (validate()) {
      console.log(state);
      clearStates();
      setShow(false);
      alert("Thank You..!! Will Get Back To YOu soon..!!");
    } else {
      alert("Please Enter Proper Details ");
    }
  };

  const EnquiryHandleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Modal className="reg-modal-content" show={show} onHide={handleClose}>
        <Modal.Header className="border-bottom-0">
          <Modal.Title className="reg-title">Quick Enquiry</Modal.Title>
          <button
            className="get-in-touch-btn-close "
            onClick={EnquiryHandleClose}
          >
            <MdClose size={25} className="get-in-touch-btn-close-icon" />
          </button>
        </Modal.Header>
        <Modal.Body className="pt-0 reg-modal-body">
          <div className="w-100 mb-3 px-2">
            {/* <label className="mb-1">Full Name</label> */}
            <input
              type="text"
              className="reg-form-control"
              placeholder="Full Name"
              autoComplete="off"
              name="FullName"
              value={state.FullName}
              onChange={handleChange}
            />
            {state.errors ? (
              <p className="req-errors">{state.errors.FullName}</p>
            ) : null}
          </div>
          <div className="w-100 mb-3 px-2">
            {/* <label className="mb-1">Mobile Number</label> */}
            <input
              type="text"
              className="reg-form-control"
              placeholder="Mobile Number"
              autoComplete="off"
              name="Mobile"
              value={state.Mobile}
              onChange={handleChange}
            />
            {state.errors ? (
              <p className="req-errors">{state.errors.Mobile}</p>
            ) : null}
          </div>
          <div className="w-100 mb-3 px-2">
            {/* <label className="mb-1">Email ID</label> */}
            <input
              type="text"
              className="reg-form-control"
              placeholder="Email ID"
              autoComplete="off"
              name="Email"
              value={state.Email}
              onChange={handleChange}
            />
            {state.errors ? (
              <p className="req-errors">{state.errors.Email}</p>
            ) : null}
          </div>
          <div className="w-100 mb-3 px-2">
            {/* <label className="mb-1">Description</label> */}
            <textarea
              className="reg-form-control"
              placeholder="Description"
              rows={2}
              autoComplete="off"
              name="Subject"
              value={state.Subject}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="w-100 px-2 enquire-btn-wrapper">
            <div className="green-blue-btn">
              <button onClick={handleSubmit}>
                <span className="btn-text"> Submit Enquiry</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RegisterationPopUp;
