import React, { useEffect, useState } from "react";
import { IMAGES } from "../utils";
import "../Assets/Styles/Header.css";
import { MdOutlineWatchLater, MdOutlinePhoneInTalk } from "react-icons/md";
import { GrFacebookOption } from "react-icons/gr";
import { TbBrandPinterest, TbBrandLinkedin } from "react-icons/tb";
import { RiInstagramLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { CgMenuGridO } from "react-icons/cg";
import Accordion from "react-bootstrap/Accordion";
import { RiFacebookCircleLine } from "react-icons/ri";
import { Modal, Offcanvas } from "react-bootstrap";
import { RxInstagramLogo } from "react-icons/rx";
import { CiLinkedin, CiTwitter } from "react-icons/ci";
import { ImPinterest2 } from "react-icons/im";
import { MdOutlineKeyboardArrowDown, MdSearch } from "react-icons/md";
import GetGuidanceModal from "../Modals/GetGuidanceModal";
import GetInTouchModal from "../Modals/GetInTouchModal";
import { RiYoutubeLine } from "react-icons/ri";
import Marquee from "react-fast-marquee";

const Header = () => {
  const navigate = useNavigate();
  const [mbViewShow, setmbViewShow] = useState(false);

  const handleShow = () => {
    setmbViewShow(true);
  };
  const handleClose = () => {
    setmbViewShow(false);
  };

  const [show, setShow] = useState(false);
  const controlNavbar = () => {
    if (window.scrollY > 250) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  // Start Get Career Guidance
  const [GuidanceShow, setGuidanceShow] = useState(false);
  const GuidanceHandleShow = () => {
    setGuidanceShow(true);
    setmbViewShow(false);
  };

  // End Get Career Guidance
  // Start Get in touch
  const [inTouchShow, setInTouchShow] = useState(false);
  const GetHandleShow = () => {
    setInTouchShow(true);
    setmbViewShow(false);
  };
  // End Get in touch
  return (
    <>
      <div className="header-height">
        <div className="header">
          <div className="header-wrapper">
            <div className={`top-header ${show && "hidden"}`}>
              <div className="top-header-wrapper">
                <div className="top-header-left-wrapper">
                  <span></span>
                  <Marquee direction-scroll speed={80}>
                    <h4 className="addmission-text">
                      Addmission Open For Web Design, Web Development, Digital
                      Marketing, Graphics Design... More Details: +91 8554 939
                      494
                    </h4>
                  </Marquee>
                </div>
                <div className="top-header-right-wrapper">
                  <div className="d-flex header-time">
                    <MdOutlineWatchLater size={20} className="header-watch" />
                    <p>09.30am - 7.00pm</p>
                  </div>
                  <div className="d-flex header-mobile">
                    <a href="tel://+91 8668 747 836" className="d-flex">
                      <MdOutlinePhoneInTalk size={20} className="" />
                      <p>+91 8554 939 494</p>
                    </a>
                  </div>
                  <div className="d-flex top-social-icon">
                    <a
                      href="https://www.facebook.com/CodehubTechnology/"
                      target="_about"
                    >
                      {" "}
                      <GrFacebookOption size={25} className="header-icon" />
                    </a>
                    <a
                      href="https://www.instagram.com/codehubtechnology/"
                      target="_about"
                    >
                      <RiInstagramLine size={25} className="header-icon" />
                    </a>

                    <a
                      href="https://www.linkedin.com/company/94821700/admin/"
                      target="_about"
                    >
                      <TbBrandLinkedin size={25} className="header-icon" />
                    </a>
                    <a
                      href="https://in.pinterest.com/codehubt/"
                      target="_about"
                    >
                      <TbBrandPinterest size={25} className="header-icon" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCcwAxB17gR235PNZX_A-7SQ"
                      target="_about"
                    >
                      <RiYoutubeLine size={25} className="header-icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-header">
              <div className="header-logo-wrapper">
                <div className="logo-menu">
                  <Link to="/">
                    <img src={IMAGES.Logo} alt="" className="logo" />
                  </Link>
                  <div className="menu-icon">
                    <CgMenuGridO size={35} onClick={handleShow} />
                  </div>
                </div>
              </div>
              <div className="header-menu">
                <ul>
                  <li>
                    <Link to="/">
                      <p>Home</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/courses">
                      <p>Courses</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/placements">
                      <p>Placements</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <p>Services</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/aboutus">
                      <p>About Us</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contactus">
                      <p>Contact Us</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="header-right-wrapper">
                {/* <div className="btn-career-guidance">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropGuidance"
                    onClick={GuidanceHandleShow}
                  >
                    Get Career Guidance
                  </button>
                </div> */}
                <div className="green-blue-btn">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropGuidance"
                    onClick={GuidanceHandleShow}
                  >
                    <span className="btn-text"> Get Career Guidance </span>
                  </button>
                </div>

                <div className="blue-green-btn">
                  <button
                    onClick={GetHandleShow}
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropTouch"
                  >
                    <span className="btn-text">Enroll Now</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Offcanvas */}

      <Offcanvas show={mbViewShow} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <img src={IMAGES.Logo} alt="" className="logo" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mb-social-icon-section">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/dthrillsoftwaresolutions"
                  target="_about"
                >
                  <RiFacebookCircleLine
                    size={30}
                    className="header-icon facebook"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/developersthrill/"
                  target="_about"
                >
                  <RxInstagramLogo size={30} className="header-icon insta" />
                </a>
              </li>
              {/* <li>
                <a href="https://twitter.com/SoftwareDthrill" target="_about">
                  <CiTwitter size={30} className="header-icon twitter" />
                </a>
              </li> */}
              <li>
                <a
                  href="https://www.linkedin.com/company/37807420/admin/"
                  target="_about"
                >
                  <CiLinkedin size={30} className="header-icon linkedin" />
                </a>
              </li>
              <li>
                <a href="https://in.pinterest.com/codehubt/" target="_about">
                  <ImPinterest2 size={30} className="header-icon pintrest" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCcwAxB17gR235PNZX_A-7SQ"
                  target="_about"
                >
                  <RiYoutubeLine size={30} className="header-icon youtube" />
                </a>
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-start">
            <div className="mb-btn-career-guidance">
              <button
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropGuidance"
                onClick={GuidanceHandleShow}
              >
                Get Career Guidance
              </button>
            </div>
            <div className="mb-header-btn-wrapper">
              <button
                onClick={GetHandleShow}
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropTouch"
              >
                Get in Touch
              </button>
            </div>
          </div>

          <div className="mb-menu">
            <ul className="menu-content">
              <Link to="/" className="mb-header-link" onClick={handleClose}>
                Home
              </Link>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Courses
                    {/* <MdOutlineKeyboardArrowDown size={20} /> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Link to="/webdesign" onClick={handleClose}>
                        Web Design
                      </Link>
                    </p>
                    <p>
                      <Link to="/webdevelopment" onClick={handleClose}>
                        Web Development
                      </Link>
                    </p>
                    <p>
                      <Link to="/fullstackdev" onClick={handleClose}>
                        Full Stack Development
                      </Link>
                    </p>
                    <p>
                      <Link to="/digitalmarketing" onClick={handleClose}>
                        Digital Marketing
                      </Link>
                    </p>
                    <p>
                      <Link to="/graphicsdesigning" onClick={handleClose}>
                        Graphics Design
                      </Link>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* <Link
                to="/placements"
                className="mb-header-link"
                onClick={handleClose}
              >
                Placements
              </Link> */}

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Services
                    {/* <MdOutlineKeyboardArrowDown size={20} /> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Link to="/webdesignservice" onClick={handleClose}>
                        Web Design
                      </Link>
                    </p>
                    <p>
                      <Link to="/webdevelopemnetservice" onClick={handleClose}>
                        Web development
                      </Link>
                    </p>
                    <p>
                      <Link to="/digitalmarketingservice" onClick={handleClose}>
                        Digital Marketing
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="/graphicsdesigningservice"
                        onClick={handleClose}
                      >
                        Graphics Design
                      </Link>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Link
                to="/aboutus"
                onClick={handleClose}
                className="mb-header-link"
              >
                About Us
              </Link>

              <Link
                to="/contactus"
                onClick={handleClose}
                className="mb-header-link"
              >
                Contact Us
              </Link>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Get Career Guidance */}

      <GetGuidanceModal
        GuidanceShow={GuidanceShow}
        setGuidanceShow={setGuidanceShow}
      />

      {/* Get In  Touch */}
      <GetInTouchModal
        inTouchShow={inTouchShow}
        setInTouchShow={setInTouchShow}
      />
    </>
  );
};

export default Header;
