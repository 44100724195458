import React, { useState, useEffect } from "react";
import Companies from "../sections/Companies";
import Video from "../sections/Video";
import SatisfactionGuarenteed from "../sections/SatisfactionGuarenteed";
import WhoCanDoThisCourse from "../sections/WhoCanDoThisCourse";
import Testimonials from "../sections/Testimonials";
import FAQs from "../sections/FAQs";
import Blogs from "../sections/Blogs";
import Banner from "../sections/Banner";
import RequestCallBack from "../sections/RequestCallBack";
import RegisterationPopUp from "../auth/RegistrationPopUp";
import CoursesSection from "../sections/CoursesSection";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />

      {/* <TrainingModule /> */}
      <Banner />
      <CoursesSection />
      <Companies />
      <Video />
      <SatisfactionGuarenteed />
      <WhoCanDoThisCourse />
      <Testimonials />
      <FAQs />
      <RequestCallBack />
      <Blogs />
    </>
  );
};

export default Home;
