import React from "react";
import "../../Assets/Styles/Services/GraphicsDesigning.css";
import { IMAGES } from "../../utils";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { AnimationOnScroll } from "react-animation-on-scroll";
// import ServicesContact from "../Pages/ServicesContact";
import { useState, useEffect } from "react";
import RegisterationPopUp from "../../auth/RegistrationPopUp";
import ServicesContact from "../../auth/ServicesContact";

const GraphicDesign_service = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const GraphicDesign = [
    {
      icon: IMAGES.GDVisitingCard,
      heading: "Visiting Card",
      text: "This is something which represents for your company.",
    },
    {
      icon: IMAGES.GDBrochure,
      heading: "Brochure",
      text: "We design in the best way to showcase your services.",
    },
    {
      icon: IMAGES.GDCatalogue,
      heading: "Catalogue",
      text: "A design which sells your products faster than anything.",
    },
    {
      icon: IMAGES.GDCorporateIdentity,
      heading: "Corporate Identity",
      text: "It includes designing of Letterhead, Envelope, Invoices",
    },
    {
      icon: IMAGES.GDInvititionCard,
      heading: "Invitition Card",
      text: "CorporateWedding Invitations designed for your occasions.",
    },
    {
      icon: IMAGES.GDAdvertisement,
      heading: "Advertisement",
      text: "Print Advertisement Designing for all your promotional needs.",
    },
    {
      icon: IMAGES.GDNewsletter,
      heading: "Newsletter",
      text: "Right Newsletter Designing is required to attract customers.",
    },
    {
      icon: IMAGES.GDGreetings,
      heading: "Greetings",
      text: "Birthday or Festival Greeting Designing to wish Friends/Clients.",
    },
    {
      icon: IMAGES.GDBook,
      heading: "Magazine or Book Covers",
      text: "Magazine or Book Cover Designing for Publication Houses.",
    },
  ];
  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="Gd-service-section">
        <div className="gd-banner">
          <img src={IMAGES.GDBanner} alt="" />
        </div>
        <div className="gd-section">
          <img src={IMAGES.GDPrintDesignGreen} alt="" className="gd-green" />
          <img src={IMAGES.GDPrintDesignPurple} alt="" className="gd-purple" />
          <div className="gd-print-design">
            <div className="gd-headings">
              <h3>
                Print <span>Design</span>
              </h3>
              <AnimationOnScroll animateIn="animate__fadeInUp">
                <p>
                  Companies use graphic design service to promote and sell
                  products as well as to make services more pleasing manner.
                  Also it is very showing information into graphics for a
                  visiter/user to understanding the product or service.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="gd-card-section">
            <div className="row">
              {GraphicDesign?.map((gd, idx) => (
                <div className="col-lg-4 col-md-6 col-sm-12" key={idx}>
                  <div className="gd-card-wrapper">
                    <div className="gd-print-card">
                      <div className="gd-pc-inner">
                        <img src={gd.icon} alt="" />
                        <h5 className="print-card-heading">{gd.heading}</h5>
                        <p className="print-card-text">{gd.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="logo-design">
            <div className="gd-headings">
              <h3>
                Logo <span>Design</span>.
              </h3>
              <AnimationOnScroll animateIn="animate__fadeInUp">
                <p>
                  We are a leading Graphics Design Company in Pune, Maharashtra
                  offering a wide range of creative designer services like Logo,
                  Visiting Cards, Social Media Posts, Brochure, Catalogue,
                  Invitation Card, Advertisement, Magazine, Book Covers,
                  Greetings etc. We look at your requirements & handle every
                  design part of your company. We provide customized, unique
                  comprehensive solutions that maximize return on your
                  investment.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="brand-guid">
            <div className="gd-headings">
              <h3>
                Brand <span>Guidlines</span>.
              </h3>
              <div className="bg-wrapper">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="brand-img-container">
                      <img src={IMAGES.GDBrand1} alt="" className="brand-img" />{" "}
                      <p className="brand-panel-name">Flyer, Letterhead, Envelope, ID Card, Leaflet, Visiting Card, Product Design</p>
                      
                      {/* <div className="brand-para">
                        <p>
                          Blue Frontier created a modern style logo for Rob
                          Llewellyn, an international digital transformation
                          advisor. It was essential to the client that the logo
                          stayed impactful in various sizes. We went with a
                          strong stamp style design to give the name some punch
                          while still being legible across a host of mediums
                          such as the website, podcast cover art and social
                          media branding.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="brand-img-container">
                      <img src={IMAGES.GDBrand2} alt="" className="brand-img" />{" "}
                      <p className="brand-panel-name">Product Design</p>
                      {/* <div className="brand-para">
                        <p>
                          Perfect Senses are a bespoke wedding and events
                          planner based in Thailand. They were looking for a
                          professional, modern style logo design that would make
                          them stand out within their field and appeal to a wide
                          range of clientele looking for the services that they
                          have to offer.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="brand-img-container">
                      <img src={IMAGES.GDBrand3} alt="" className="brand-img" />{" "}
                      <p className="brand-panel-name">Hoarding, Banner, Poster Design</p>
                      {/* <div className="brand-para">
                        <p>
                          Tally Ho Drinks Co offer a mobile bar service, served
                          from a converted vintage horse trailer. We delivered
                          to them a traditional, yet stylish logo that they
                          could use across their branding and livery. We
                          incorporated the horse graphic into the design to fit
                          in with the theme and complement the colourful Tally
                          Ho Sanderson fabric and wallpaper that decorates the
                          inside of the trailer.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="brand-img-container">
                      <img src={IMAGES.GDBrand4} alt="" className="brand-img" />{" "}
                      <p className="brand-panel-name">Booklet Design</p>
                      {/* <div className="brand-para">
                        <p>
                          Resolute Engineering is a UK based bespoke engineering
                          and specialist welding company. Blue Frontier created
                          the brand logo using a bold industrial style look that
                          the client required to showcase the business in the
                          best possible way. Using the 'R' from the brand name
                          to create a strong emblem to sit at the front of the
                          logo worked really well with this design.
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ServicesContact /> */}
        </div>
      </div>

      <ServicesContact/>
    </>
  );
};

export default GraphicDesign_service;
