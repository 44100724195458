import React, { useState } from "react";
import "../Assets/Styles/ContactUs.css";
import { FiPhoneCall } from "react-icons/fi";
import { SlLocationPin } from "react-icons/sl";
import { BsClockHistory } from "react-icons/bs";
import { IMAGES } from "../utils";
import { codehubiumail } from "../config/api";
const ContactUs = () => {
  const [state, setState] = useState({
    ContactID: 0,
    FullName: "",
    EmailID: "",
    Mobile: "",
    Message: "",
    Service: "",
    Course: "",
    CreatedBy: 1,
    errors: [],
  });
  const handleChnge = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });
  };
  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!state.FullName) {
      IsValid = false;
      errors["FullName"] = "Full Name Is Required";
    } else if (state.FullName.length < 2) {
      IsValid = false;
      errors["FullName"] = "Must Be 15 Characters ";
    } else if (!state.FullName.match(regex_name)) {
      IsValid = false;
      errors["FullName"] = "Please Enter Valid Name";
    } else {
      errors["FullName"] = "";
    }

    if (!state.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile number Is Required";
    } else if (state.Mobile.length < 10 || state.Mobile.length > 10) {
      IsValid = false;
      errors["Mobile"] = "Mobile Number Must Have 10 Digits";
    } else if (!state.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }

    if (!state.EmailID) {
      IsValid = false;
      errors["EmailID"] = "Email ID Is Required";
    } else if (!state.EmailID.match(regex_email)) {
      IsValid = false;
      errors["EmailID"] = "Please Enter valid Email id";
    } else {
      errors["EmailID"] = "";
    }

    if (!state.Service) {
      IsValid = false;
      errors["Service"] = "Enter service you want";
    } else {
      errors["Service"] = "";
    }
    if (!state.Course) {
      IsValid = false;
      errors["Course"] = "Please select course";
    } else {
      errors["Course"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const clearState = () => {
    setState({
      ContactID: 0,
      FullName: "",
      EmailID: "",
      Mobile: "",
      Message: "",
      Service: "",
      Course: "",
      CreatedBy: 1,
    });
  };

  const handleSubmit = async () => {
    console.log("submit-->", state);
    if (validate()) {
      await codehubiumail(state).then((res) => {
        console.log("contact--->", res[0]);
        if (res.length == 1) {
          alert("your message successfully sent to us...!!");
          clearState();
        }
      });
      alert("done");
    } else {
      alert("Please Enter Proper Details..!");
    }
  };

  return (
    <>
      <div className="main-contactus-page">
        <div className="main-contactus-inner">
          <div className="container">
            <div className="emptydiv"></div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="main-contact-detail">
                  <ul>
                    <li>
                      <div className="contact-call-us">
                        <FiPhoneCall size={20} className="contact-form-icon" />
                        <h5>CALL US</h5>
                      </div>
                      <p>+91-8668 747 836</p>
                    </li>
                    <li>
                      <div className="contact-call-us">
                        <SlLocationPin
                          size={20}
                          className="contact-form-icon"
                        />
                        <h5>LOCATION</h5>
                      </div>
                      <p>
                        203, Shri Krishna Appartment, Katepuram Chowk, <br />
                        Pimple Gurav, Pune, Maharashtra - 411061
                      </p>
                    </li>
                    <li>
                      <div className="contact-call-us">
                        <BsClockHistory
                          size={20}
                          className="contact-form-icon"
                        />
                        <h5>WORKING HOURS</h5>
                      </div>
                      <p>Mon – Fri …… 10 am – 8 pm, Sun ...Closed</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <form className="bg-white main-contactform">
                  <div className="row">
                    <div className="col-md-12">
                      <label for="fullname">Full Name</label>
                      <input
                        type="text"
                        id="fullname"
                        className="form-control contact-input-class"
                        placeholder="Full Name"
                        name="FullName"
                        value={state.FullName}
                        onChange={handleChnge}
                      />
                    </div>
                    {state.errors ? (
                      <p className="req-errors">{state.errors.FullName}</p>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label for="Mobile">Mobile</label>
                      <input
                        type="number"
                        id="Mobile"
                        className="form-control contact-input-class"
                        placeholder="Enter Mobile"
                        name="Mobile"
                        value={state.Mobile}
                        onChange={handleChnge}
                      />
                    </div>
                    {state.errors ? (
                      <p className="req-errors">{state.errors.Mobile}</p>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label for="Email">Email</label>
                      <input
                        type="email"
                        id="Email"
                        className="form-control contact-input-class"
                        placeholder="Enter Email.."
                        name="EmailID"
                        value={state.EmailID}
                        onChange={handleChnge}
                      />
                    </div>
                    {state.errors ? (
                      <p className="req-errors">{state.errors.EmailID}</p>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label for="Service">Service</label>
                      <input
                        type="text"
                        id="Service"
                        className="form-control contact-input-class"
                        placeholder="Write Service you want .."
                        name="Service"
                        value={state.Service}
                        onChange={handleChnge}
                      />
                    </div>
                    {state.errors ? (
                      <p className="req-errors">{state.errors.Service}</p>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-md-12 ">
                      <label for="Course">Course</label>
                      <select
                        className="form-select contact-input-class"
                        name="Course"
                        onChange={handleChnge}
                        value={state.Course}
                      >
                        <option>Select Course Type</option>

                        <option value="Web Design">Web Design</option>
                        <option value="Web development">Web development</option>
                        <option value="Full Stack Development">
                          Full Stack Development
                        </option>
                        <option value="Digital Marketing">
                          Digital Marketing
                        </option>
                        <option value="Graphics Design">Graphics Design</option>
                        <option value="Python ">Python </option>
                        <option value="Power BI">Power BI</option>
                        <option value="Business Analyst">
                          Business Analyst
                        </option>
                        <option value="Software Testing">
                          Software Testing
                        </option>
                        <option value="Aptitude Courses">
                          Aptitude Courses
                        </option>
                      </select>
                    </div>
                    {state.errors ? (
                      <p className="req-errors">{state.errors.Course}</p>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label for="message">Message</label>
                      <textarea
                        type="textarea"
                        className="form-control contact-input-class"
                        placeholder="Message"
                        aria-label="message"
                        name="Message"
                        value={state.Message}
                        onChange={handleChnge}
                      />
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-md-12" onClick={handleSubmit}>
                      <div className="contact-btn-submit">
                        <a href="#" className="animated-button1">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          Send
                        </a>
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col-md-12">
                      <div className="contact-btn-submit-wrapper">
                        <div className="contact-btn-submit">
                          <a  onClick={handleSubmit}>
                            <span className="btn-text">Send{" "}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
