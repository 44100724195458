import React, { useState, useEffect } from "react";
import "../Assets/Styles/AboutUs.css";
import { IMAGES } from "../utils";
import RegisterationPopUp from "../auth/RegistrationPopUp";

const AboutUs = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />

      <section className="AboutSection-2">
        <div className="container">
          <div className=" AboutSection-2_bg">
            <div className="AboutSection-2Wrapper">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="AboutSection-2_imageWrapper">
                    <div className="AboutSection-2_Img">
                      <img src={IMAGES.aboutUsSection2} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="aboutsectionTextWrapper">
                    <div className="aboutsectionText">
                      <h3>About Our Company </h3>
                      <p>
                        A{" "}
                        <strong className="fs-5">
                          {" "}
                          <a href="https://codehubtech.com/" target="_blank">
                            Code Hub Technology
                          </a>
                        </strong>{" "}
                        training center, also known as a training institute or
                        training facility, is an institution that offers various
                        training programs and courses to individuals.
                      </p>
                      <p>
                        We focus on providing specific Skills, Knowledge, or
                        expertise in a particular field or industry. Training
                        centers can cover a wide range of subjects, including
                        Professional Development, Technical Skills, Vocational
                        Training, and Personal Growth.
                      </p>
                      <p>
                        <strong className="fs-5">
                          {" "}
                          <a href="https://codehubtech.com/" target="_blank">
                            Code Hub Technology
                          </a>
                        </strong>{" "}
                        training Centers cater to the training needs of
                        businesses and organizations. They provide Digital
                        Marketing, Web Design, Web Development, and Graphics
                        Design courses that enhance employees' Skills and
                        knowledge in areas such as Leadership, Communication,
                        Project Management, Sales, Customer Service, and
                        Technology.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutusSection-2_cardsBody">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="cardsWrapper">
                    <div className="training">
                      <h3>Training</h3>
                      <p>
                        We are established with Vision to Educate people with
                        Right Technical and Practical Skills, So that they can
                        reduce the Gap Between the Skills provided by Education
                        System and The Skills required by The Job Industry.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="cardsWrapper">
                    <div className="consulting">
                      <h3>Consulting</h3>{" "}
                      <p>
                        {" "}
                        Many confuse two things. Either to hire an In-house team
                        or Outsource it to a Consultancy. We are the solution
                        for it. We are consultants, will work with your In-house
                        team to Deliver Results.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="cardsWrapper">
                    <div className="placement">
                      <h3>Placements</h3>
                      <p>
                        {" "}
                        Some Placement agents still work on the same Job Portals
                        for Recruiting potential candidates. We go beyond the
                        Portals. We have a network pool of candidates database
                        from Freshers to Executives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mission-vission-section">
        <div className="aboutSection-1-row">
          <div className="container">
            <div className="row mission-vission-row">
              <div className="col-lg-6 col-md-6 col-sm-12 mission-vission-wrapper-img">
                <div className="mission-img">
                  <img src={IMAGES.missionVission} alt="Mission vission" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="mission-vission-content">
                  <div className="technology-text-mission">
                    <h3>Mission</h3>
                    <p>
                      Our mission is to empower individuals with comprehensive
                      IT skills and knowledge through our meticulously designed
                      industry-orineted course curriculum with a seamlessly
                      blended learning approach and hands on practical
                      applications.
                    </p>
                  </div>
                  <div className="technology-text-vission">
                    <h3>Vision</h3>
                    <p>
                      We aim to to be the leading IT training company that
                      enables learners to unlock their full potential in the
                      digital age and equip them with the expertise and
                      confidence to thrive on their chosen career paths.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="AboutSection-try">
        <div className="whatElse-body-try">
          <div className="row">
            <div className="col-lg-6">
              <div className="whatElseWeDo-right-try">
                <div className="whatElseWeDo-right-content-try">
                  <div className="whatElseText-try">
                    <p>
                      We are passionate about technology and dedicated to
                      empowering our clients with the tools they need to succeed
                      in today's rapidly evolving digital landscape. Partner
                      with us to unlock the full potential of technology for
                      your business or personal endeavors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </section>
      <section className="AboutSection-3">
        <div className="whatElse-body">
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <div className="whatElseWeDo-right">
                <div className="whatElseWeDo-right-content">
                  <div className="whatElseHeading">
                    <h3>What other activities can we engage in?</h3>
                    <h3 className="coopTraining">ORGANIZATIONAL TRAINING</h3>
                  </div>
                  <div className="whatElseText">
                    <p>
                      In addition to our core IT services, we also offer
                      comprehensive corporate training programs designed to
                      enhance the skills and knowledge of professionals in
                      various domains. We understand the importance of
                      continuous learning and professional development in
                      today's fast-paced business environment, and our corporate
                      training solutions are tailored to address the specific
                      needs of organizations and their workforce.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section>
        <div className="slider-desktop">
          <Carousel controls={false} indicators={false} className="relative">
            <Carousel.Item>
              <div className="videoSlider">
                <video
                  width="100%"
                  className="elVideo"
                  loop="loop"
                  autoPlay
                  playsInline
                  muted
                  src="https://www.youtube.com/embed/FsrMPexJkI4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  // src="https://video.wixstatic.com/video/2b2e29_67857aa3bb16417ea7909ddc8d01b6e0/720p/mp4/file.mp4"
                  id="video-slider-1"
                ></video>
              </div>

              <div className="carousel-text1">
                <div className="carousel-inner-wrapper1">
                  <h3>Pune Digital Internet Marketing Agency</h3>
                  <p>Grow Your ROI With the best digital Marketing solution</p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="videoSlider">
                <video
                  width="100%"
                  className="elVideo"
                  loop="loop"
                  autoPlay
                  playsInline
                  muted
                  src="https://ak.picdn.net/shutterstock/videos/1025732684/preview/stock-footage-two-male-colleagues-employees-cooperating-in-office-talking-working-together-at-workplace-smiling.webm"
                  id="video-slider-1"
                ></video>
              </div>
              <div className="carousel-text2">
                <div className="carousel-inner-wrapper2">
                  <h3>Pune Digital Internet Marketing Agency</h3>
                  <p>Grow Your ROI With the best digital Marketing solution</p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="videoSlider">
                <video
                  width="100%"
                  className="elVideo"
                  loop="loop"
                  autoPlay
                  playsInline
                  muted
                  src="https://ak.picdn.net/shutterstock/videos/1033204736/preview/stock-footage-closeup-coding-on-screen-man-hands-coding-html-and-programming-on-screen-laptop-development-web.webm"
                  id="video-slider-1"
                ></video>
              </div>
              <div className="carousel-text3">
                <div className="carousel-inner-wrapper3">
                  <h3>Pune Digital Internet Marketing Agency</h3>
                  <p>Grow Your ROI With the best digital Marketing solution</p>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section> */}
    </>
  );
};

export default AboutUs;
