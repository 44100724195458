import React, { useEffect, useRef, useState } from "react";
import "../Assets/Styles/FAQs.css";
import { IMAGES } from "../utils";
import { MdRemove, MdAdd } from "react-icons/md";

const FAQs = () => {
  const [subMenuOpen, setSubMenuOpen] = useState(-1);
  const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);

  const faq = [
    {
      id: 1,
      que: "How about help with placement?",
      ans: "All the Courses Are Merged With Placement Assistance",
    },
    {
      id: 2,
      que: "Are the course fees in my price range?",
      ans: "We Are Committed For Lowest Course Fees in the Market",
    },
    {
      id: 3,
      que: "Do you offer institutional certification once the course is completed?",
      ans: "Yes! We do provide Certification straight after completion of the Course",
    },
    // {
    //   id: 4,
    //   que: "How about the Course Discount offered?",
    //   ans: "Sorry! We don’t refund fees in any Condition.",
    // },
    {
      id: 4,
      que: "I'm concerned about the possibility of payment installments?",
      ans: "Yes, this Course has heavy Offer discount in fees if you pay in One Shot/ Group Admission!",
    },
    // {
    //   id: 6,
    //   que: "Does This Course Include Any Practical Sessions?",
    //   ans: "Don’t Worry! We Do Have Flexible Fees Installment Option",
    // },
    {
      id: 5,
      que: "Is there a global certification included with the course?",
      ans: "Yes! This Course Comes With Live Practical Sessions And Labs",
    },
    // {
    //   id: 8,
    //   que: "Will the Global Certification Exam be administered by your institute?",
    //   ans: "Sure! Most of our Course Comes with Global Certification for which you have to give Exam at the End of the Course",
    // },
    // {
    //   id: 9,
    //   que: "What is your refund policy?",
    //   ans: "Sorry! We don’t refund fees in any Condition.",
    // },
  ];
  // const containerRef = useRef(null);

  // useEffect(() => {
  //   const container = containerRef.current;

  //   if (container) {
  //     const items = container.getElementsByClassName("item");

  //     Array.from(items).forEach((item) => {
  //       let itemToClone = item;

  //       for (let i = 1; i < 4; i++) {
  //         itemToClone = itemToClone.nextElementSibling;

  //         // wrap around if at the end of the item collection
  //         if (!itemToClone) {
  //           itemToClone = item.parentNode.firstElementChild;
  //         }

  //         // grab item, clone, add marker class, add to collection
  //         const clonedItem = itemToClone.firstElementChild.cloneNode(true);
  //         clonedItem.classList.add(`cloneditem-${i}`);
  //         item.appendChild(clonedItem);
  //       }
  //     });
  //   }
  // }, []);
  return (
    <>
      <div className="faqs">
        <div className="container">
          <div className="faq-heading">
            <h3>Frequently Ask Questions</h3>
            <h5>Our students' top concerns, as asked most frequently</h5>
          </div>
          <div className="faqs-section">
            <div className="row faqs-section-flex">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="faq-outer-wrapper">
                  {faq?.map((f, idx) => (
                    <div className="main-que" key={idx}>
                      <div
                        className="que-icon"
                        onClick={() => toggleMenu(f.id)}
                      >
                        <h5>
                          {f.id}. {f.que}
                        </h5>
                        <div>
                          {subMenuOpen === f.id ? (
                            <MdRemove
                              size={20}
                              className="faq-minus-plus-icon"
                            />
                          ) : (
                            <MdAdd size={20} className="faq-minus-plus-icon" />
                          )}
                        </div>
                      </div>

                      <div
                        className={`sub-menu ${
                          subMenuOpen === f.id ? "is-open" : ""
                        }`}
                      >
                        <div className="">
                          <p className="">{f.ans}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="faq-outer-wrapper">
                  <div className="faq-img-wrapper">
                    <img src={IMAGES.FAQs} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container">
          <h3>
            Based on{" "}
            <a href="https://github.com/rtpHarry/Bootstrap3-ShowManySlideOneCarousel">
              Bootstrap3-ShowManySlideOneCarousel
            </a>
          </h3>
          <p>
            <kbd>TODO</kbd> Try sliding the entire group of slides.
          </p>

          <div
            id="carousel-1"
            className="carousel slide slide-by-one-in-group"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carousel-1"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#carousel-1" data-slide-to="1"></li>
              <li data-target="#carousel-1" data-slide-to="2"></li>
              <li data-target="#carousel-1" data-slide-to="3"></li>
              <li data-target="#carousel-1" data-slide-to="4"></li>
              <li data-target="#carousel-1" data-slide-to="5"></li>
            </ol>
            <div className="carousel-inner" role="listbox">
              <div className="item active">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                  <img
                    src="https://via.placeholder.com/500/c69/f9c/?text=1"
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                  <img
                    src="https://via.placeholder.com/500/9c6/cf9/?text=2"
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                  <img
                    src="https://via.placeholder.com/500/69c/9cf/?text=3"
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                  <img
                    src="https://via.placeholder.com/500/c69/f9c/?text=4"
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                  <img
                    src="https://via.placeholder.com/500/9c6/cf9/?text=5"
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                  <img
                    src="https://via.placeholder.com/500/69c/9cf/?text=6"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <a
              className="left carousel-control"
              href="#carousel-1"
              role="button"
              data-slide="prev"
            >
              <span
                className="glyphicon glyphicon-chevron-left"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control"
              href="#carousel-1"
              role="button"
              data-slide="next"
            >
              <span
                className="glyphicon glyphicon-chevron-right"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default FAQs;
