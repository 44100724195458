import React from "react";
import "../../Assets/Styles/Services/WebDevelopment.css";
import { IMAGES } from "../../utils";
import { Carousel } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ServicesContact from "../../auth/ServicesContact";
import { useState, useEffect } from "react";
import RegisterationPopUp from "../../auth/RegistrationPopUp";

const WebDevelopment_service = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="webdev-service-section">
        <div className="devCard-solution">
          <div className="devCard-wrapper">
            <div className="container">
              <div className="web-design-banner-text">
                <h3>Web Development</h3>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="dev-section">
            <div className="dev-wrapper">
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.WebsiteDev} alt="" />
              </AnimationOnScroll>
              <div className="dev-para-wrapper-odd">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="dev-para">
                    <h3>Website Development</h3>
                    <p>
                      From full eCommerce solutions like DThrill eCommerce
                      Platform, to information sites on popular platforms like
                      WordPress and Drupal, we can develop a custom solution to
                      fit your exact needs. Our cost-effective solutions are
                      created to perform optimally. Our agency has worked with
                      clients from a variety of industries, to develop
                      professional websites that add value to their businesses.
                      Our bespoke templates ensure that your site stands out
                      from the rest.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>

          <div className="dev-section">
            <div className="dev-wrapper-even">
              <div className="dev-para-wrapper-even">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="dev-para">
                    <h3>Design Services Prioritising User Experience</h3>
                    <p>
                      It’s surprisingly rare to come across a website that is
                      beautifully designed and also technically sound. Our goal
                      is to create and develop websites that excel on both
                      fronts.
                    </p>
                    <p>
                      Through design and usability, we give your customers many
                      great reasons to explore your web pages for longer..
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.UserExp} alt="" />
              </AnimationOnScroll>
            </div>
          </div>

          <div className="dev-section">
            <div className="dev-wrapper">
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.TechExpertise} alt="" />
              </AnimationOnScroll>
              <div className="dev-para-wrapper-odd">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="dev-para">
                    <h3>Experience and Technical Expertise</h3>
                    <p>
                      Our experienced team uses their technical and creative
                      knowledge to ensure that your website offers a faultless
                      user experience to customers.
                    </p>
                    <p>
                      From building bespoke themes, to creating bespoke
                      extensions to add specific functionality – we are
                      proficient at the latest web technologies, and specialise
                      in open-source platforms like DThrill Custom Platform,
                      WordPress and more.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>

          <div className="dev-section">
            <div className="dev-wrapper-even">
              <div className="dev-para-wrapper-even">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="dev-para">
                    <h3>Mobile-Friendly Experiences</h3>
                    <p>
                      Users aren’t simply sitting behind keyboards anymore,
                      they’re also looking to access online information on the
                      go – using tablets, smartphones and other mobile devices.
                      A successful website must be compatible with mobile
                      platforms, or risk losing a large number of potential
                      customers.
                    </p>
                    <p>
                      DThrill focuses on developing mobile-friendly websites, to
                      ensure that searchers can easily find your company
                      anytime, anywhere, on any device.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.MobileFriendly} alt="" />
              </AnimationOnScroll>
            </div>
          </div>
        </div>
        {/* <ServicesContact /> */}
      </div>
      <ServicesContact />
    </>
  );
};

export default WebDevelopment_service;
