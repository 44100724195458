import React from "react";
import "../Assets/Styles/Companies.css";
import { IMAGES } from "../utils";
import Marquee from "react-fast-marquee";

const Companies = () => {
  const partner1 = [
    { img: IMAGES.AbhitaLand },
    { img: IMAGES.DreamEstate },
    { img: IMAGES.AbhitaLand },
    { img: IMAGES.Legavac },
    { img: IMAGES.DreamEstate },
    { img: IMAGES.AbhitaLand },
    { img: IMAGES.Legavac },
    { img: IMAGES.DreamEstate },
    { img: IMAGES.AbhitaLand },
    { img: IMAGES.Legavac },
    { img: IMAGES.DreamEstate },
    { img: IMAGES.DreamEstate },
  ];
  const partner2 = [
    { img: IMAGES.AbhitaLand },
    { img: IMAGES.Legavac },
    { img: IMAGES.DreamEstate },
    { img: IMAGES.DreamEstate },
    { img: IMAGES.AbhitaLand },
    { img: IMAGES.Legavac },
    { img: IMAGES.DreamEstate },
    { img: IMAGES.AbhitaLand },
    { img: IMAGES.Legavac },
    { img: IMAGES.DreamEstate },
    { img: IMAGES.AbhitaLand },
    { img: IMAGES.Legavac },
    { img: IMAGES.DreamEstate },
  ];

  return (
    <>
      <div className="companies">
        <div className="container">
          <div className="companies-heading">
            <h3>Companies</h3>
            <h5>Our Active Projects and Valuable Clients.</h5>
          </div>

          <div className="companies-section">
            {/* <div className="container-img">
            <div className="sliding-background">
              <img src={IMAGES.AbhitaLand} alt="" />
               <img src={IMAGES.Cowork} alt="" />
              <img src={IMAGES.Legavac} alt="" />
              <img src={IMAGES.AbhitaFinance} alt="" />
              <img src={IMAGES.DreamEstate} alt="" />
              <img src={IMAGES.SahuBankGramin} alt="" />
              <img src={IMAGES.OBC} alt="" />
              <img src={IMAGES.SahuBankMulti} alt="" />
              <img src={IMAGES.CityBank} alt="" />
              <img src={IMAGES.Year99} alt="" /> 
            </div>
          </div>
          <div className="comp-img">
            <img src={IMAGES.Img2} alt="" />
          </div>
          <div className="container-img">
            <div className="sliding-background">
              <img src={IMAGES.AbhitaLand} alt="" />
              <img src={IMAGES.Cowork} alt="" />
              <img src={IMAGES.Legavac} alt="" />
              <img src={IMAGES.AbhitaFinance} alt="" />
              <img src={IMAGES.DreamEstate} alt="" />
              <img src={IMAGES.SahuBankGramin} alt="" />
              <img src={IMAGES.OBC} alt="" />
              <img src={IMAGES.SahuBankMulti} alt="" />
              <img src={IMAGES.CityBank} alt="" />
              <img src={IMAGES.Year99} alt="" />
            </div>
          </div> */}

            <Marquee
              direction-scroll
              speed={80}
              delay={5}
              className="container-img"
            >
              <img src={IMAGES.AbhitaLand} alt="" />
              <img src={IMAGES.Cowork} alt="" />
              <img src={IMAGES.AbhitaFinance} alt="" />
              <img src={IMAGES.DreamEstate} alt="" />
              <img src={IMAGES.SahuBankGramin} alt="" />
              <img src={IMAGES.OBC} alt="" />
              <img src={IMAGES.SahuBankMulti} alt="" />
              <img src={IMAGES.CityBank} alt="" />
              <img src={IMAGES.Year99} alt="" />
              <img src={IMAGES.Legavac} alt="" />
            </Marquee>
          </div>
        </div>
      </div>
    </>
  );
};

export default Companies;
