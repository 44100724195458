import React, { useState } from "react";
import { RiAccountCircleLine } from "react-icons/ri";
import { TfiEmail } from "react-icons/tfi";
import { MdOutlinePhone } from "react-icons/md";
import { IMAGES } from "../utils";

const ServicesContact = () => {
  const [state, setState] = useState({
    FullName: "",
    Email: "",
    Mobile: "",
    Subject: "",
    Message: "",
    errors:[],
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!state.FullName) {
      IsValid = false;
      errors["FullName"] = "Full Name Is Required";
    } else if (state.FullName.length < 2) {
      IsValid = false;
      errors["FullName"] = "Must Be 15 Characters ";
    } else if (!state.FullName.match(regex_name)) {
      IsValid = false;
      errors["FullName"] = "Please Enter Valid Name";
    } else {
      errors["FullName"] = "";
    }

    if (!state.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile number Is Required";
    } else if (state.Mobile.length < 10 || state.Mobile.length > 10) {
      IsValid = false;
      errors["Mobile"] = "Mobile Number Must Have 10 Digits";
    } else if (!state.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }

    if (!state.Email) {
      IsValid = false;
      errors["Email"] = "Email ID Is Required";
    } else if (!state.Email.match(regex_email)) {
      IsValid = false;
      errors["Email"] = "Please Enter valid Email id";
    } else {
      errors["Email"] = "";
    }

    if (!state.Subject) {
      IsValid = false;
      errors["Subject"] = "Subject  Is Required";
    } else {
      errors["Subject"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };
  const handleSubmit = () => {
    if (validate()) {
      console.log("ssubmit-->", state);
    } else {
      alert("Please Enter  Proper Fields");
    }
  };
  return (
    <>
      <div className="service-contact-section">
        <div className="container">
          <div className="row service-flex">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="service-contact-img-wrapper">
                <img src={IMAGES.ContactLady} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="service-contact-wrapper">
                <div className="service-contact-inner-wrapper">
                  <div className="service-contact-heading">
                    <h3>Get In Touch</h3>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-contact-inputWrapper">
                        <input
                          type="text"
                          className="service-contact-form-control"
                          placeholder="Full Name"
                          name="FullName"
                          value={state.FullName}
                          onChange={handleChange}
                        />
                        <RiAccountCircleLine
                          size={20}
                          className="service-contact-input-icon"
                        />
                      </div>
                      {state.errors ? (
                        <p className="req-errors">{state.errors.FullName}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-contact-inputWrapper">
                        <input
                          type="number"
                          className="service-contact-form-control"
                          placeholder="Mobile No"
                          name="Mobile"
                          value={state.Mobile}
                          onChange={handleChange}
                        />
                        <MdOutlinePhone
                          size={20}
                          className="service-contact-input-icon"
                        />
                      </div>
                      {state.errors ? (
                        <p className="req-errors">{state.errors.Mobile}</p>
                      ) : null}
                    </div>
                    <div className="col-lg-12">
                      <div className="service-contact-inputWrapper">
                        <input
                          type="email"
                          className="service-contact-form-control"
                          placeholder="Email Id"
                          name="Email"
                          value={state.Email}
                          onChange={handleChange}
                        />
                        <TfiEmail
                          size={20}
                          className="service-contact-input-icon"
                        />
                      </div>
                      {state.errors ? (
                        <p className="req-errors">{state.errors.Email}</p>
                      ) : null}
                    </div>
                    <div className="col-lg-12">
                      <div className="service-contact-inputWrapper">
                        <input
                          type="text"
                          className="service-contact-form-control"
                          placeholder="Subject"
                          name="Subject"
                          value={state.Subject}
                          onChange={handleChange}
                        />
                      </div>
                      {state.errors ? (
                        <p className="req-errors">{state.errors.Subject}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-contact-inputWrapper">
                        <textarea
                          type="textarea"
                          className="service-contact-form-control"
                          placeholder="Message"
                          name="Message"
                          value={state.Message}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="service-contact-inputWrapper service-contact-btnSubmit">
                      <button
                        type="submit"
                        className="animated-button1"
                        onClick={handleSubmit}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesContact;
